import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export const SaveCloseDialog = ({
  open,
  saveAndClose,
  discardAndClose,
  setDialogOpen,
  isInactive,
}) => {
  const handleClose = () => {
    setDialogOpen(false);
  };

  
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Unsaved changes"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {"You have made changes - would you like to save or discard them?"}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {!isInactive && (
          <Button variant="contained" onClick={saveAndClose}>
            Save and close
          </Button>
        )}
        <Button onClick={discardAndClose} variant="contained" color="secondary">
          Discard changes
        </Button>
        <Button onClick={handleClose} variant="contained" color="secondary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
