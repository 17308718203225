import { Clear, Delete, Download, Edit, Upload } from "@mui/icons-material";
import {
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  ListItem,
  Snackbar,
} from "@mui/material";
import React, { useState } from "react";
import ConfirmDialog from "components/shared/ConfirmDialog";
import { db, nextPushId} from "config/config";
import { doc, collection, getDocs, query, where } from "firebase/firestore";
import {
  deleteDocumentType,
  editDocumentType,
  uploadFile,
  uploadFileResumable,
} from "apis/documents";

export default function Table({
  documentTypes,
  handleDocumentData,
  setAddDialogOpen: setDataDialogOpen,
  handleDownload,
  reloadData,
}) {
  const [searchValue, setSearchValue] = useState("");
  const [loadingAnimation, setLoadingAnimation] = useState();
  const [deleteDocumentId, setDeleteDocumentId] = useState(false);
  const [customElement, setCustomElement] = useState();





  // const handleDelete = async () => {
  //   await deleteDocumentType(deleteDocumentId);
  //   setDeleteDocumentId(false);
  //   await reloadData();
  // };

  const handleDelete = async () => {
      
    const projectsRef = collection(db, "projects");
  
  
    const q = query(projectsRef);
    const querySnapshot = await getDocs(q);
  
    
    const isDocumentTypeInUse = querySnapshot.docs.some((doc) => {
      const projectData = doc.data();
  
      return projectData.documents?.some(
        (document) => document.documentType === deleteDocumentId
      );
    });
  
    if (isDocumentTypeInUse) {
      setCustomElement(
        <Snackbar
          autoHideDuration={3000}
          open={true}
          message="Cannot delete. This document type is currently in use."
        />
      );
      setDeleteDocumentId(false);
     
      setTimeout(() => {
        setCustomElement();
      }, 3000);
    } else {
      await deleteDocumentType(deleteDocumentId);
     setDeleteDocumentId(false);
      await reloadData();
    }
  };
  








  const handleUpload = async (file, id) => {
    file = file?.[0] || file;

    if (!file) return;

    if (
      file.type !==
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      setCustomElement(
        <Snackbar
          autoHideDuration={6000}
          open={true}
          message="The only supported format is .docx"
        />,
      );

      setLoadingAnimation(false);

      setTimeout(() => {
        setCustomElement();
      }, 3000);

      return;
    }
    const fileRes = uploadFileResumable(
      `documentsCustomizedFiles/${nextPushId()()}`,
      file,
    );
    fileRes.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

        setLoadingAnimation({
          // style: { top: "50%", left: "50%", transform: "translate(-50%,-50%)" },
          variant: "determinate",
          value: progress,
          content: "Uploading...",
        });
      },
      () => {
        setLoadingAnimation(false);
      },
      async () => {
        // let documentsTmp = items;

        // documentsTmp[index] = {
        //   ...documentsTmp[index],
        //   fileUrl: fileRes?.snapshot?.metadata?.fullPath,
        // };
        await editDocumentType({
          data: { fileUrl: fileRes?.snapshot?.metadata?.fullPath },
          id,
        });
        // await handleDocumentData(documentsTmp);
        setCustomElement(
          <Snackbar
            autoHideDuration={3000}
            open={true}
            message="File uploaded to document Successfully"
          />,
        );
        setTimeout(() => {
          setCustomElement();
        }, 3000);
        setLoadingAnimation(false);
      },
    );
  };
  const renderItems = documentTypes.map((document, index) => (
    <ListItem key={index}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Grid item>{document?.name}</Grid>

        <Grid item sx={{ display: "flex" }}>
          <IconButton
            onClick={() => handleDownload(`${document?.fileUrl}`)}
            title="Download a copy of the Document Type"
          >
            <Download />
          </IconButton>

          {/* <IconButton
            onClick={(e) =>
              e?.target?.closest("button")?.children?.[0]?.click()
            }
            title="Upload a new version of the Document Type"
          >
            <input
              id="contained-button-file"
              style={{ display: "none" }}
              accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              type="file"
              onChange={(e) => handleUpload(e.target.files, document.id)}
            />
            <Upload />
          </IconButton> */}
          <Divider
            variant="middle"
            sx={{
              background: "#002977",
              width: "1px",
              height: "30px",
              marginBlock: "auto",
            }}
          />

          <IconButton
            onClick={() => setDataDialogOpen(document.id)}
            title="Update the name of the Document Type"
          >
            <Edit />
          </IconButton>

          <IconButton
            onClick={
              () => setDeleteDocumentId(document.id)
              //   handleDelete(project.id, project.customerReference)
            }
            title="Delete the Document Type"
          >
            <Delete />
          </IconButton>
        </Grid>
      </Grid>
    </ListItem>
  ));

  return (
    <div>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
        textAlign={"left"}
        padding={"1rem 1rem 0 1rem"}
        sx={{ display: { xs: "none", md: "flex" } }}
      >
        <Grid item xs={12} md={2} lg={2}>
          <span>Name</span>
        </Grid>
      </Grid>

      {renderItems}
      {customElement}
      <CircularProgress
        {...loadingAnimation}
        style={{
          display: loadingAnimation ? "" : "none",
          position: "fixed",
          right: "10%",
          bottom: "10%",
          ...loadingAnimation?.style,
        }}
      />
      <ConfirmDialog
        {...{
          open: deleteDocumentId,
          title: "Delete Document",
          content: `Are you sure you want to delete Document Type (${
            documentTypes.find((type) => type.id === deleteDocumentId)?.name
          }) ? This action cannot be undone`,
          handleClose: () => setDeleteDocumentId(false),
          handleConfirm: handleDelete,
        }}
      />
    </div>
  );
}
