import { CircularProgress, Snackbar } from "@mui/material";
import { db } from "config/config";
import {
  collection,
  documentId,
  getCountFromServer,
  getDocs,
  limit,
  onSnapshot,
  or,
  and,
  orderBy,
  query,
  startAfter,
  endBefore,
  where,
} from "firebase/firestore";
import { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import tablesDataControl from "config/advancedTableColumnsControl.json";
import { Context } from "context/Wrapper";
const searchLimit = tablesDataControl?.searchMinLength;

export const listenAndFilterTo = (props) => {
  const { setData, user, condition, setContext, collectionName } = props;

  const conditions = props?.conditions || [],
    setLastRef = props?.setLastRef || (() => { });

  if (!(user?.groupId?.length > 0 && user?.userId?.length > 0)) {
    setData([]);
    return () => { };
  }

  try {
    const q = condition
      ? query(
        collection(db, collectionName),
        or(
          where("groupId", "==", user?.groupId),
          where("userId", "==", user.userId)
        ),
        ...conditions
      )
      : query(
        collection(db, collectionName),
        where("userId", "==", user.userId),
        ...conditions
      );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      if (querySnapshot.docs?.length > 0)
        setLastRef(querySnapshot.docs[querySnapshot.docs.length - 1]);
      const newDocs = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      setData(newDocs);
    });

    return unsubscribe;
  } catch (err) {
    console.log(err);
    setData([]);

    setContext({ snackbar: <Snackbar open={true} message={err?.message} /> });

    setTimeout(() => {
      setContext({ snackbar: null });
    }, 4000);

    return () => { };
  }
};

// export const useAdvancedDataListener = ({
//   // setData,
//   user,
//   condition,
//   fetchAll,
//   // setContext,
//   collectionName,
//   // conditions = [],
//   // setLastRef = () => {},
//   // rowsLimit,
//   // params,
// }) => {
//   const context = useContext(Context);

//   const [params] = useSearchParams();
//   const [data, setData] = useState([]);
//   const [count, setCount] = useState(0);
//   const [lastVisible, setLastVisible] = useState(null);


//   const [allDataLoaded, setAllDataLoaded] = useState(false);




//   const extraConditions = fetchAll ? [] : condition
//     ? [
//       or(
//         where("groupId", "==", user?.groupId),
//         where("userId", "==", user?.userId)
//       ),
//     ]
//     : [where("userId", "==", user?.userId)];


//   useEffect(() => {
//     setAllDataLoaded(false);
//   }, [condition]);

//   useEffect(() => {
//     if (!(user?.groupId?.length > 0 && user?.userId?.length > 0)) return;
//     fetchTotalItems();
//     fetchPage(params?.get("page"));
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [user, `${params}`, condition]);

//   const fetchTotalItems = async () => {
//     const coll = condition
//       ? query(collection(db, collectionName), ...extraConditions)
//       : query(collection(db, collectionName), ...extraConditions);
//     const snapshot = await getCountFromServer(coll);
//     const totalItems = snapshot.data().count;
//     setCount(totalItems);
//   };

//   const fetchPage = async (page) => {
//     console.log(page)
//     if (allDataLoaded) return;

//     context?.setState &&
//       context?.setState({
//         snackbar: (
//           <CircularProgress
//             size={46}
//             style={{
//               position: "fixed",
//               right: "10%",
//               bottom: "5%",
//               zIndex: 9
//             }}
//           />
//         ),
//       });


//     let queryRef = query(
//       collection(db, collectionName),
//       orderBy(documentId(), "desc"),
//       limit(params.get("pageSize") || tablesDataControl?.defaultRowsLimit),
//       ...extraConditions
//     );



//     if (page > 0 && lastVisible) {
//       queryRef = query(
//         collection(db, collectionName),
//         orderBy(documentId(), "desc"),
//         startAfter(lastVisible),
//         limit(params.get("pageSize") || tablesDataControl?.defaultRowsLimit),
//         ...extraConditions
//       );
//     }




//     if (params.get("search") && params.get("search")?.length >= searchLimit) {
//       queryRef = query(
//         collection(db, collectionName),
//         orderBy(documentId(), "desc"),
//         ...extraConditions
//       );
//       setAllDataLoaded(true);
//     }

//     const querySnapshot = await getDocs(queryRef);
//     const docs = querySnapshot.docs.map((doc) => ({
//       id: doc.id,
//       ...doc.data(),
//     }));
//     setData(docs);

//     setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);

//     setTimeout(() => {
//       context?.setState({ snackbar: null });
//     }, 1000);
//   };

//   // Add refetch function to allow manual re-fetching of data
//   const refetch = () => {
//     setLastVisible(null); 

//     setAllDataLoaded(false); 
//     fetchTotalItems(); 
//     fetchPage(0); 
//   };

//   return { data, count, refetch};
// };





// export const useAdvancedDataListener = ({
//   user,
//   condition,
//   fetchAll,
//   collectionName,
// }) => {
//   const context = useContext(Context);
//   const [params] = useSearchParams();
//   const [data, setData] = useState([]);
//   const [count, setCount] = useState(0);
//   const [lastVisibleDocs, setLastVisibleDocs] = useState([]); 
//   const [firstVisibleDocs, setFirstVisibleDocs] = useState([]);
//   const [allDataLoaded, setAllDataLoaded] = useState(false);



//   const extraConditions = fetchAll
//     ? []
//     : condition
//     ? [
//         or(
//           where("groupId", "==", user?.groupId),
//           where("userId", "==", user?.userId)
//         ),
//       ]
//     : [where("userId", "==", user?.userId)];

//   useEffect(() => {
//     setAllDataLoaded(false);
//   }, [condition]);

//   useEffect(() => {
//     if (!(user?.groupId?.length > 0 && user?.userId?.length > 0)) return;
//     fetchTotalItems();
//     fetchPage(params?.get("page") || 0);
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [user, `${params}`, condition]);

//   const fetchTotalItems = async () => {
//      const coll = query(collection(db, collectionName),...extraConditions);
   
//     const snapshot = await getCountFromServer(coll);
//     setCount(snapshot.data().count);
//   };

  

//   const fetchPage = async (page) => {
//     if (allDataLoaded) return;

//     context?.setState({
//         snackbar: (
//             <CircularProgress
//                 size={46}
//                 style={{
//                     position: "fixed",
//                     right: "10%",
//                     bottom: "5%",
//                     zIndex: 9,
//                 }}
//             />
//         ),
//     });

//     let queryRef;

//     // Check for a search parameter
//     if (params.get("search") && params.get("search").length >= searchLimit) {
//         queryRef = query(
//             collection(db, collectionName),
//             orderBy(documentId(), "desc"),
//             ...extraConditions
//         );
//         setAllDataLoaded(true); // Mark that all data is loaded for search
//     } else if (page > 0 && lastVisibleDocs[page - 1]) {
//         // Moving forward
//         queryRef = query(
//             collection(db, collectionName),
//             orderBy(documentId(), "desc"),
//             startAfter(lastVisibleDocs[page - 1]),
//             limit(params.get("pageSize") || tablesDataControl?.defaultRowsLimit),
//             ...extraConditions
//         );
//     } else if (page > 0 && firstVisibleDocs[page]) {
//         // Moving backward
//         queryRef = query(
//             collection(db, collectionName),
//             orderBy(documentId(), "desc"),
//             endBefore(firstVisibleDocs[page]),
//             limit(params.get("pageSize") || tablesDataControl?.defaultRowsLimit),
//             ...extraConditions
//         );
//     } else {
//         // Fetch first page
//         queryRef = query(
//             collection(db, collectionName),
//             orderBy(documentId(), "desc"),
//             limit(params.get("pageSize") || tablesDataControl?.defaultRowsLimit),
//             ...extraConditions
//         );
//     }

//     const querySnapshot = await getDocs(queryRef);
//     const docs = querySnapshot.docs.map((doc) => ({
//         id: doc.id,
//         ...doc.data(),
//     }));
//     setData(docs);

//     // Update last and first visible documents for the current page
//     if (page >= 0) {
//         lastVisibleDocs[page] = querySnapshot.docs[querySnapshot.docs.length - 1];
//         firstVisibleDocs[page] = querySnapshot.docs[0];
//         setLastVisibleDocs([...lastVisibleDocs]);
//         setFirstVisibleDocs([...firstVisibleDocs]);
//     }

//     setTimeout(() => {
//         context?.setState({ snackbar: null });
//     }, 1000);
// };




//   const refetch = () => {
//     setLastVisibleDocs([]); 
//     setFirstVisibleDocs([]);
//     setAllDataLoaded(false); 
//     fetchTotalItems(); 
//     fetchPage(0); 
//   };

//   return { data, count, refetch };
// };


export const useAdvancedDataListener = ({
  user,
  condition,
  fetchAll,
  collectionName,
  fromBuildingReferencesJobDetails,
}) => {
  const context = useContext(Context);
  const [params] = useSearchParams();
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [lastVisibleDocs, setLastVisibleDocs] = useState([]);
  const [firstVisibleDocs, setFirstVisibleDocs] = useState([]);
  const [allDataLoaded, setAllDataLoaded] = useState(false);

  // Building ID condition if passed
  const buildingIdCondition = fromBuildingReferencesJobDetails?.buildingId
    ? [where("building", "==", fromBuildingReferencesJobDetails.buildingId)]
    : [];

  // Additional conditions based on user access
  const extraConditions = fetchAll
    ? []
    : condition
      ? [
        or(
          where("groupId", "==", user?.groupId),
          where("userId", "==", user?.userId)
        ),
      ]
    : [where("userId", "==", user?.userId)];

    //Combine conditions with `and(...)`

    let combinedConditions;
    if(fromBuildingReferencesJobDetails?.fromBuildingReferencesJob){
      combinedConditions = [and(...[...extraConditions, ...buildingIdCondition])];
    }else{
      combinedConditions = [...extraConditions];
    }
  


  useEffect(() => {
    setAllDataLoaded(false);
  }, [condition, fromBuildingReferencesJobDetails?.buildingId]);

  useEffect(() => {
    if (!(user?.groupId?.length > 0 && user?.userId?.length > 0)) return;
    fetchTotalItems();
    fetchPage(params?.get("page") || 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, `${params}`, condition, fromBuildingReferencesJobDetails?.buildingId]);

  const fetchTotalItems = async () => {
    const coll = query(collection(db, collectionName), ...combinedConditions);
    const snapshot = await getCountFromServer(coll);
    setCount(snapshot.data().count);
  };

  const fetchPage = async (page) => {
    if (allDataLoaded) return;

    context?.setState({
      snackbar: (
        <CircularProgress
          size={46}
          style={{
            position: "fixed",
            right: "10%",
            bottom: "5%",
            zIndex: 9,
          }}
        />
      ),
    });

    let queryRef;

    if (params.get("search") && params.get("search").length >= searchLimit) {
      queryRef = query(
        collection(db, collectionName),
        collectionName === "invoices" ? orderBy("billingDate", "desc") : orderBy(documentId(), "desc"),
        ...combinedConditions
      );
      setAllDataLoaded(true);
    } else if (page > 0 && lastVisibleDocs[page - 1]) {
      queryRef = query(
        collection(db, collectionName),
        collectionName === "invoices" ? orderBy("billingDate", "desc") : orderBy(documentId(), "desc"),
        startAfter(lastVisibleDocs[page - 1]),
        limit(params.get("pageSize") || tablesDataControl?.defaultRowsLimit),
        ...combinedConditions
      );
    } else if (page > 0 && firstVisibleDocs[page]) {
      queryRef = query(
        collection(db, collectionName),
        collectionName === "invoices" ? orderBy("billingDate", "desc") : orderBy(documentId(), "desc"),
        endBefore(firstVisibleDocs[page]),
        limit(params.get("pageSize") || tablesDataControl?.defaultRowsLimit),
        ...combinedConditions
      );
    } else {
      queryRef = query(
        collection(db, collectionName),
        collectionName === "invoices" ? orderBy("billingDate", "desc") : orderBy(documentId(), "desc"),
        limit(params.get("pageSize") || tablesDataControl?.defaultRowsLimit),
        ...combinedConditions
      );
    }


    const querySnapshot = await getDocs(queryRef);
    const docs = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setData(docs);

    if (page >= 0) {
      lastVisibleDocs[page] = querySnapshot.docs[querySnapshot.docs.length - 1];
      firstVisibleDocs[page] = querySnapshot.docs[0];
      setLastVisibleDocs([...lastVisibleDocs]);
      setFirstVisibleDocs([...firstVisibleDocs]);
    }

    setTimeout(() => {
      context?.setState({ snackbar: null });
    }, 1000);
  };

  const refetch = () => {
    setLastVisibleDocs([]);
    setFirstVisibleDocs([]);
    setAllDataLoaded(false);
    fetchTotalItems();
    fetchPage(0);
  };

  return { data, count, refetch };
};



export const useFetchCollectionDocs = ({
  collectionName,
  conditions = [],
  setContext = () => { },
}) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        if (conditions?.length === 0) return;

        const data = await getDocs(
          query(collection(db, collectionName), ...conditions)
        );

        if (data.empty) return;

        return setData(
          data.docs?.map((doc) => ({
            ...doc?.data(),
            id: doc.id,
          })) || []
        );
      } catch (error) {
        setContext({
          snackbar: <Snackbar open={true} message={error?.message} />,
        });

        setTimeout(() => {
          setContext({ snackbar: null });
        }, 4000);

        console.log("error", error);
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionName, JSON.stringify(conditions)]);

  return data;
};
