import React, { useState, useEffect } from "react";

import { Grid, TextField, Fab, Box } from "@mui/material";

import columnDefs from "../config/columns.json";

import { Close } from "@mui/icons-material";

import axios from "axios";
// styles

import Fade from "@mui/material/Fade";
import CountCard from "../components/CountCard";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

export default function Status(props) {
  const { thisContact, currentBuilding, thisProject } = props;

  const [table, setTable] = useState({
    showTable: false,
    columnDefs: "",
    data: [],
    title: "",
    showDetail: false,
    row: {},
  });

  const handleTableOpen = (title, tableName) => {
    setTable({
      showTable: true,
      columnDefs: columnDefs[tableName],
      data: DOBData[tableName].map((row, index) => ({ ...row, id: index })),
      title: title,
    });
  };

  const hideTable = () => {
    setTable({ showTable: false });
  };

  var [DOBData, setDOBData] = useState({
    DOBData: [],
    DOBNowData: [],
    Violations: [],
    Permits: [],
    DOBNowPermits: [],
    Complaints: [],
    Asbestos: [],
    Boilers: [],
    CATS: [],
    ECBViolations: [],
    DOBNowElectricalFilings: [],
    loading: true,
  });

  const handleSetRow = (event) => {
    setTable({ ...table, row: event.row, showDetail: true });
  };

  const handleCloseDetail = () => {
    setTable({ ...table, showDetail: false });
  };

  useEffect(() => {
    Promise.all([
      getDOBJobs(),
      getDOBNowJobs(),
      getViolations(),
      getPermits(),
      getDOBNowPermits(),
      getComplaints(),
      getAsbestosFilings(),
      getBoilers(),
      getCats(),
      getEcbViolations(),
      getElectricalNow(),
    ]).then(
      ([
        DOBData,
        DOBNowData,
        Violations,
        Permits,
        DOBNowPermits,
        Complaints,
        Asbestos,
        Boilers,
        CATS,
        ECBViolations,
        DOBNowElectricalFilings,
      ]) => {
        setDOBData({
          DOBData,
          DOBNowData,
          Violations,
          Permits,
          DOBNowPermits,
          Complaints,
          Asbestos,
          Boilers,
          CATS,
          ECBViolations,
          DOBNowElectricalFilings,
          loading: false,
        });
      },
    );
  }, []);

  const getPermits = () => {
    return axios
      .get(
        `https://data.cityofnewyork.us/resource/ipu4-2q9a.json?bin__=${currentBuilding.BIN}&$limit=50000`,
      )
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        return [];
      });
  };

  const getDOBNowPermits = () => {
    return axios
      .get(
        `https://data.cityofnewyork.us/resource/rbx6-tga4.json?bin=${currentBuilding.BIN}&$limit=50000`,
      )
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        return [];
      });
  };

  const getCats = () => {
    return axios
      .get(
        `https://data.cityofnewyork.us/resource/f4rp-2kvy.json?bin=${currentBuilding.BIN}`,
      )
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        return [];
      });
  };

  const getBoilers = () => {
    return axios
      .get(
        `https://data.cityofnewyork.us/resource/52dp-yji6.json?bin_number=${currentBuilding.BIN}`,
      )
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        return [];
      });
  };

  const getElectricalNow = () => {
    return axios
      .get(
        `https://data.cityofnewyork.us/resource/dm9a-ab7w.json?bin=${currentBuilding.BIN}`,
      )
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        return [];
      });
  };

  const getEcbViolations = () => {
    return axios
      .get(
        `https://data.cityofnewyork.us/resource/6bgk-3dad.json?bin=${currentBuilding.BIN}`,
      )
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        return [];
      });
  };

  const getAsbestosFilings = () => {
    return axios
      .get(
        `https://data.cityofnewyork.us/resource/vq35-j9qm.json?bin=${currentBuilding.BIN}`,
      )
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        return [];
      });
  };

  const getComplaints = () => {
    return axios
      .get(
        `https://data.cityofnewyork.us/resource/eabe-havv.json?bin=${currentBuilding.BIN}`,
      )
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        return [];
      });
  };

  const getDOBJobs = () => {
    return axios
      .get(
        `https://data.cityofnewyork.us/resource/rvhx-8trz.json?bin__=${currentBuilding.BIN}&$limit=50000&$where=job_status!='X'`,
      )
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        return [];
      });
  };

  const getDOBNowJobs = () => {
    return axios
      .get(
        `https://data.cityofnewyork.us/resource/w9ak-ipjd.json?bin=${currentBuilding.BIN}&$limit=50000`,
      )
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        return [];
      });
  };

  const getViolations = () => {
    return axios
      .get(
        `https://data.cityofnewyork.us/resource/3h2n-5cm9.json?bin=${currentBuilding.BIN}`,
      )
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        return [];
      });
  };

  if (DOBData.loading) {
    return (
      <>
        <Fade in>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <CountCard
                number={DOBData.DOBData.length}
                title="BIS filings"
                loading={true}
              />
            </Grid>
            <Grid item xs={3}>
              <CountCard
                number={DOBData.DOBNowData.length}
                title="DOB Now! filings"
                loading={true}
              />
            </Grid>
            <Grid item xs={3}>
              <CountCard number={DOBData.Permits.length} title="Permits" />
            </Grid>
            <Grid item xs={3}>
              <CountCard
                number={DOBData.DOBNowPermits.length}
                title="DOB Now Permits"
              />
            </Grid>
            <Grid item xs={3}>
              <CountCard
                number={DOBData.DOBNowElectricalFilings.length}
                title="DOB Now! Electrical filings"
                loading={true}
              />
            </Grid>
            <Grid item xs={3}>
              <CountCard
                number={DOBData.Asbestos.length}
                title="Asbestos filings"
                loading={true}
              />
            </Grid>
            <Grid item xs={3}>
              <CountCard
                number={DOBData.Boilers.length}
                title="Boiler filings"
                loading={true}
              />
            </Grid>
            <Grid item xs={3}>
              <CountCard
                number={DOBData.CATS.length}
                title="CATS filings"
                loading={true}
              />
            </Grid>
            <Grid item xs={3}>
              <CountCard
                number={DOBData.Violations.length}
                title="Violations"
                loading={true}
              />
            </Grid>
            <Grid item xs={3}>
              <CountCard
                number={DOBData.ECBViolations.length}
                title="ECB Complaints"
              />
            </Grid>
            <Grid item xs={3}>
              <CountCard
                number={DOBData.Complaints.length}
                title="Complaints"
                loading={true}
              />
            </Grid>
          </Grid>
        </Fade>
      </>
    );
  }
  return (
    <>
      {table.showTable ? (
        table.showDetail ? (
          <>
            <Grid container spacing={2} sx={{ w: "100%" }}>
              {table.columnDefs.map(
                (column) =>
                  table.row[column.field] &&
                  table.row[column.field] !== "0" && (
                    <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        label={column.headerName}
                        variant="outlined"
                        value={table.row[column.field]}
                        fullWidth
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                  ),
              )}
            </Grid>
            <Box
              sx={{
                position: "fixed",
                bottom: "1rem",
                right: "1rem",
                "& > :not(style)": { m: 1 },
              }}
            >
              <Fab
                color="secondary"
                aria-label="add"
                variant="extended"
                onClick={handleCloseDetail}
              >
                <Close sx={{ mr: 1 }} />
                Close
              </Fab>
            </Box>
          </>
        ) : (
          <div style={{ display: "flex", height: "100%" }}>
            <div style={{ flexGrow: 1 }}>
              <DataGrid
                rows={table.data}
                columns={table.columnDefs}
                onRowClick={(e) => {
                  handleSetRow(e);
                }}
                sx={{ mr: 1, position: "static", bottom: 0, right: 0 }}
                slots={{
                  toolbar: GridToolbar,
                }}
              ></DataGrid>
              <div
                style={{
                  position: "fixed",
                  bottom: 0,
                  right: 0,
                  margin: "1rem",
                }}
              >
                <Fab
                  color="secondary"
                  aria-label="add"
                  variant="extended"
                  onClick={() => hideTable()}
                >
                  <Close />
                  Back
                </Fab>
              </div>
            </div>
          </div>
        )
      ) : (
        <>
          <Fade in>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <CountCard
                  number={DOBData.DOBData.length}
                  title="BIS filings"
                  handleTableOpen={handleTableOpen}
                  tableName={"DOBData"}
                />
              </Grid>
              <Grid item xs={3}>
                <CountCard
                  number={DOBData.DOBNowData.length}
                  title="DOB Now! filings"
                  handleTableOpen={handleTableOpen}
                  tableName={"DOBNowData"}
                />
              </Grid>
              <Grid item xs={3}>
                <CountCard
                  number={DOBData.Permits.length}
                  title="Permits"
                  handleTableOpen={handleTableOpen}
                  tableName={"Permits"}
                />
              </Grid>
              <Grid item xs={3}>
                <CountCard
                  number={DOBData.DOBNowPermits.length}
                  title="DOB Now Permits"
                  handleTableOpen={handleTableOpen}
                  tableName={"DOBNowPermits"}
                />
              </Grid>
              <Grid item xs={3}>
                <CountCard
                  number={DOBData.DOBNowElectricalFilings.length}
                  title="DOB Now! Electrical filings"
                  handleTableOpen={handleTableOpen}
                  tableName={"DOBNowElectricalFilings"}
                />
              </Grid>
              <Grid item xs={3}>
                <CountCard
                  number={DOBData.Asbestos.length}
                  title="Asbestos filings"
                  handleTableOpen={handleTableOpen}
                  tableName={"Asbestos"}
                />
              </Grid>
              <Grid item xs={3}>
                <CountCard
                  number={DOBData.Boilers.length}
                  title="Boiler filings"
                  handleTableOpen={handleTableOpen}
                  tableName={"Boilers"}
                />
              </Grid>
              <Grid item xs={3}>
                <CountCard
                  number={DOBData.CATS.length}
                  title="CATS filings"
                  handleTableOpen={handleTableOpen}
                  tableName={"CATS"}
                />
              </Grid>
              <Grid item xs={3}>
                <CountCard
                  number={DOBData.Violations.length}
                  title="Violations"
                  handleTableOpen={handleTableOpen}
                  tableName={"Violations"}
                />
              </Grid>
              <Grid item xs={3}>
                <CountCard
                  number={DOBData.ECBViolations.length}
                  title="ECB Violations"
                  handleTableOpen={handleTableOpen}
                  tableName={"ECBViolations"}
                />
              </Grid>
              <Grid item xs={3}>
                <CountCard
                  number={DOBData.Complaints.length}
                  title="Complaints"
                  handleTableOpen={handleTableOpen}
                  tableName={"Complaints"}
                />
              </Grid>
            </Grid>
          </Fade>
        </>
      )}
    </>
  );
}
