import React, { useEffect, useState, useRef, useContext } from 'react'
import { useParams } from 'react-router-dom';
import { getDoc, doc } from 'firebase/firestore';
import {
    Box, Grid, Typography, Table, TableHead, TableBody, TableRow, TableCell, Paper, Tab,
    Tabs,
} from '@mui/material';
import Fab from "@mui/material/Fab";
import PrintIcon from '@mui/icons-material/Print';
import TableContainer from '@mui/material/TableContainer';
import { db } from 'config/config';
import { TabPanel } from './ContactDetail';
import { useReactToPrint } from 'react-to-print';
import InvoiceNew from './invoiceNew';
import { Context } from 'context/Wrapper';
import dayjs from 'dayjs';
import jsPDF from 'jspdf';
import { Close } from "@mui/icons-material";
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import { useNavigate } from "react-router-dom";


export const ComponentToPrint = React.forwardRef((props, ref) => {
    // const { invoiceId } = useParams();
    const context = useContext(Context);
    const userProfile = context?.state?.userProfile || {};

    const params = useParams();
    const invoiceId = params?.invoiceId ? params.invoiceId : props?.invoiceId;
    const [invoiceData, setInvoiceData] = useState(null);
    const [invoiceForData, setInvoiceForData] = useState(null);
    const [invoiceJobData, setInvoiceJobData] = useState(null);
    const { tab, handleChangeTab, setIsLoading } = props;

    // Function to fetch invoice document
    const fetchInvoice = async (invoiceId) => {
        try {
            const docRef = doc(db, 'invoices', invoiceId);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                return docSnap.data();  // Return the invoice data
            } else {
                throw new Error('Invoice not found');
            }
        } catch (err) {
            throw err;
        }
    };

    // Function to fetch additional documents (invoiceFor and invoiceJob)
    const fetchRelatedDocument = async (collectionName, docId) => {
        try {
            const docRef = doc(db, collectionName, docId);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                return docSnap.data();  // Return the related document data
            }
        } catch (err) {
            throw err;
        }
    };


    useEffect(() => {
        let isMounted = true; // track if the component is mounted
        const fetchData = async () => {
            try {
                const invoice = await fetchInvoice(invoiceId);

                if (isMounted) { // check if the component is still mounted
                    setInvoiceData(invoice);

                    if (invoice) {
                        if (invoice?.invoiceFor?.length > 0) {
                            const invoiceFor = await fetchRelatedDocument('contacts', invoice.invoiceFor);
                            if (isMounted) {
                                setInvoiceForData(invoiceFor);
                            }
                        }

                        if (invoice.invoiceJob) {
                            const invoiceJob = await fetchRelatedDocument('projects', invoice.invoiceJob);
                            if (isMounted) {
                                setInvoiceJobData(invoiceJob);
                            }
                        }
                    }
                }
            } catch (err) {
                if (isMounted) {
                    console.error('Error fetching data:', err);
                }
            } finally {
                if (setIsLoading) setIsLoading(false); // Stop loading after data is fetched
            }
        };

        fetchData();

        return () => {
            isMounted = false; // cleanup to prevent state updates
        };
    }, [invoiceId, tab]);


    // console.log(invoiceData);
    // console.log(invoiceJobData);
    // console.log(invoiceForData);

    return (
        <>
            <Typography sx={{ textTransform: "uppercase" }} align="left" variant="h4">
                {invoiceData?.name}'s Invoice
            </Typography>
            <Box sx={{ borderBottom: 1, borderColor: "divider", paddingLeft: 0 }}>
                <Tabs
                    value={tab}
                    onChange={handleChangeTab}
                    aria-label="basic tabs example"
                >
                    <Tab label="Preview" />
                    {userProfile.subscription !== "inactive" &&
                        <Tab label="Edit Invoice" />}
                </Tabs>
            </Box>

            <TabPanel value={tab} index={0}>
                <Box
                    sx={{
                        width: '100%',
                        maxWidth: '66%',
                        boxShadow: 3,
                        margin: 'auto',
                        marginTop: 8,
                        borderRadius: 2,
                    }}
                >
                    <div ref={ref}>
                        <Grid container sx={{ backgroundColor: 'black', color: 'white' }}>
                            <Grid item xs={6} sx={{ padding: 2 }}>
                                <img style={{ maxWidth: "50%" }} src={userProfile?.logoUrl} />
                                <Typography variant="h5" gutterBottom>
                                    {invoiceData?.name}
                                </Typography>

                                <Typography variant="body2">{invoiceData?.address}</Typography>
                                <Typography variant="body2">{invoiceData?.email}</Typography>
                                <Typography variant="body2">{invoiceData?.phone}</Typography>

                            </Grid>
                            <Grid item xs={6} sx={{ padding: 2 }}>
                                <Typography variant="h5" gutterBottom>
                                    Invoice
                                </Typography>
                                <Typography variant="body2">Invoice Number:</Typography>
                                <Typography variant="body2" color="textSecondary">
                                    {invoiceData?.invoiceNumber}
                                </Typography>
                                <Typography variant="body2">Billing Date:</Typography>
                                <Typography variant="body2" color="textSecondary">
                                    {dayjs(invoiceData?.billingDate).format('YYYY-MM-DD')}
                                </Typography>
                                <Typography variant="body2">Payment due:</Typography>
                                <Typography variant="body2" color="textSecondary">
                                    {dayjs(invoiceData?.dueDate).format('YYYY-MM-DD')}
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item xs={6} sx={{ padding: 2 }}>
                                <Typography variant="h6" gutterBottom>
                                    Invoice For:
                                </Typography>
                                {
                                    invoiceForData?.fullName && <Typography variant="subtitle1">{invoiceForData?.fullName}</Typography>
                                }
                                {
                                    invoiceForData?.businessName && <Typography variant="subtitle1">{invoiceForData?.businessName}</Typography>
                                }
                                {
                                    invoiceForData?.address1 && <Typography variant="subtitle1">{invoiceForData?.address1}</Typography>
                                }
                                {
                                    invoiceForData?.address2 && <Typography variant="subtitle1">{invoiceForData?.address2}</Typography>
                                }

                                {(invoiceForData?.city || invoiceForData?.state) && (
                                    <Typography variant="subtitle1">
                                        {invoiceForData.city}{invoiceForData.city && invoiceForData.state ? ', ' : ''}{invoiceForData.state}
                                    </Typography>
                                )}

                                {
                                    invoiceForData?.ZIP && <Typography variant="subtitle1">{invoiceForData?.ZIP}</Typography>
                                }
                                {
                                    invoiceForData?.email && <Typography variant="subtitle1">E-mail: {invoiceForData?.email}</Typography>
                                }
                                {
                                    invoiceForData?.phone && <Typography variant="subtitle1">Phone: {invoiceForData?.phone}</Typography>
                                }
                            </Grid>

                            <Grid item xs={6} sx={{ padding: 2 }}>
                                <Typography variant="h6" gutterBottom>
                                    For Job:
                                </Typography>
                                <Typography variant="subtitle1">{invoiceJobData?.customerReference}</Typography>
                            </Grid>
                        </Grid>


                        <Grid container>
                            <Grid item xs={12} md={12} lg={12} sx={{ padding: 2 }}>
                                <Box sx={{ padding: 2 }}>
                                    <Typography variant="h5" gutterBottom>
                                        Current Charges
                                    </Typography>
                                    <TableContainer component={Paper}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Date</TableCell>
                                                    <TableCell>Type</TableCell>
                                                    <TableCell>Hours</TableCell>
                                                    <TableCell>At</TableCell>
                                                    <TableCell>Amount</TableCell>
                                                    <TableCell>Description</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    invoiceData?.currCharegs?.map((item) => (
                                                        <TableRow>


                                                            <TableCell>
                                                                {dayjs(item.itemDate).format('YYYY-MM-DD')}
                                                            </TableCell>
                                                            <TableCell>{item.itemType}</TableCell>
                                                            <TableCell>{item.itemHours}</TableCell>
                                                            <TableCell>${item.itemHourPrice}</TableCell>
                                                            <TableCell>${item.itemAmount}</TableCell>
                                                            <TableCell>{item.itemDescription}</TableCell>
                                                        </TableRow>
                                                    ))
                                                }

                                                {
                                                    // <TableRow>
                                                    //     <TableCell colSpan={3} align="left">
                                                    //         <Typography variant="subtitle2" fontWeight="bold">
                                                    //             TOTAL AMOUNT
                                                    //         </Typography>
                                                    //     </TableCell>
                                                    //     <TableCell align="right">
                                                    //         <Typography variant="subtitle2" fontWeight="bold">
                                                    //             0.00
                                                    //         </Typography>
                                                    //     </TableCell>
                                                    // </TableRow>
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>

                                </Box>
                            </Grid>
                            <Grid item xs={12} md={8} lg={8} sx={{ padding: 2 }}>
                                <Box sx={{ padding: 2 }}>
                                    <Typography variant="h5" gutterBottom>
                                        Payments
                                    </Typography>
                                    <TableContainer component={Paper}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="left">Date</TableCell>
                                                    <TableCell align="left">Amount</TableCell>
                                                    <TableCell align="left">Type</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    invoiceData?.payments?.map((item) => (
                                                        <TableRow>

                                                            <TableCell align="left">
                                                                {dayjs(item.itemPaymentsDate).format('YYYY-MM-DD')}
                                                            </TableCell>
                                                            <TableCell align="left">${item.itemPaymentsAmount}</TableCell>
                                                            <TableCell align="left">{item.itemPaymentsType}</TableCell>
                                                        </TableRow>
                                                    ))
                                                }

                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                            </Grid>

                            <Grid item xs={12} md={4} lg={4} sx={{ padding: 2 }}>
                                <Box sx={{ padding: 2 }}>
                                    <Typography variant="h6" gutterBottom>
                                        Payment Details
                                    </Typography>
                                    <Typography variant="subtitle1" gutterBottom>
                                        Total Charges: <strong>${invoiceData?.totalCharges}</strong>
                                    </Typography>
                                    <Typography variant="subtitle1" gutterBottom>
                                        Total Payments: <strong>${invoiceData?.totalPayments}</strong>
                                    </Typography>
                                    <Typography variant="subtitle1" gutterBottom>
                                        Adjustments: <strong>${invoiceData?.adjustments}</strong>
                                    </Typography>
                                    <hr />
                                    <Typography variant="subtitle1" gutterBottom>
                                        Balance Due: <strong>${invoiceData?.balanceDue}</strong>
                                    </Typography>
                                </Box>
                            </Grid>
                                                        {
                                invoiceData?.invoiceRemarks &&
                                <Grid item xs={12} md={12} lg={12} sx={{ padding: 2 }}>
                                    <Typography variant="subtitle2" gutterBottom>
                                        Remarks:
                                    </Typography>
                                    <Typography variant="body2" gutterBottom style={{ whiteSpace: "pre-line" }}>
                                        {invoiceData?.invoiceRemarks}
                                    </Typography>
                                </Grid>
                            }

                        </Grid>
                    </div>

                </Box >
            </TabPanel>

            {userProfile.subscription !== "inactive" &&
                <TabPanel value={tab} index={1}>
                    <InvoiceNew invoiceData={invoiceData} handleChangeTab={handleChangeTab} invoiceJobData={invoiceJobData} invoiceForData={invoiceForData} invoiceId={invoiceId} />
                </TabPanel>
            }
        </>
    )

});




export const InvoiceDetails = () => {
    const context = useContext(Context);
    const userProfile = context?.state?.userProfile || {};
    const componentRef = useRef();
    const [tab, setTab] = useState(0);
    const navigate = useNavigate();
    const [isDownloading, setIsDownloading] = useState(false);


    const handlePrint = useReactToPrint({
        content: () => componentRef.current
    });


    const handleGeneratePdf = () => {
        setIsDownloading(true);
        const doc = new jsPDF({ format: 'A4', unit: 'px', orientation: 'portrait' });
        doc.setFont('Inter-Regular', 'normal');
        doc.html(componentRef.current, {
            width: doc.internal.pageSize.getWidth(),
            windowWidth: componentRef.current.scrollWidth,
            margin: [10, 20, 30, 10],
            autoPaging: 'text',
            async callback(doc) {
                await doc.save('Invoice');
                setIsDownloading(false);
            },
        });
    };

    const handleChangeTab = (event, newValue) => {
        setTab(newValue);
    };

    return (
        <div>
            <ComponentToPrint
                ref={componentRef}
                tab={tab}
                handleChangeTab={handleChangeTab}
            />
            <Box
                sx={{
                    position: "fixed",
                    bottom: "1rem",
                    right: "1rem",
                    "& > :not(style)": { m: 1 },
                }}
            >
                {(tab === 0 && userProfile.subscription !== "inactive") && (
                    <>
                        <Fab
                            color="primary"
                            aria-label="add"
                            variant="extended"
                            onClick={handlePrint}
                        >
                            <PrintIcon sx={{ mr: 1 }} />
                            Print
                        </Fab>

                        <Fab
                            color="primary"
                            aria-label="add"
                            variant="extended"
                            onClick={handleGeneratePdf}
                            disabled={isDownloading} // Disable if loading or downloading
                        >
                            <DownloadForOfflineIcon sx={{ mr: 1 }} />
                            Download As PDF
                        </Fab>
                    </>
                )}

                <Fab
                    color="secondary"
                    aria-label="add"
                    variant="extended"
                    onClick={() => navigate("/invoices")}
                >
                    <Close sx={{ mr: 1 }} />
                    Close
                </Fab>
            </Box>
        </div>
    );
};







