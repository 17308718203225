import React, { useEffect, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import PrintIcon from '@mui/icons-material/Print';
import { ComponentToPrint } from 'pages/invoiceDetails';

const PrintInvoiceButton = ({ invoiceId }) => {
  const componentRef = useRef();
  const [isPrintReady, setIsPrintReady] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Loading state


  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => {
      setIsPrintReady(false)
      setIsLoading(true);
    } , // Remove component after print
  });

  const handlePrintClick = () => {
    setIsPrintReady(true); // Load component to prepare for printing
  };

  useEffect(()=>{
    if(isPrintReady && !isLoading) handlePrint();
  },[isPrintReady,isLoading])

  return (
    <>
      {isPrintReady && (
        <div style={{ display: 'none' }}>
          <ComponentToPrint ref={componentRef} tab={0} invoiceId={invoiceId} setIsLoading={setIsLoading} />
        </div>
      )}
      <PrintIcon onClick={handlePrintClick} disabled={!isPrintReady} />
    </>
  );
};

export default PrintInvoiceButton;
