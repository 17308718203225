import firebase from "firebase/compat/app";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  Fab,
  IconButton,
  Grid,
  Typography,
  Paper,
  InputBase,
  CircularProgress,
  TablePagination,
  Snackbar,
 
} from "@mui/material";
import { Delete, Clear, Download,  Upload, CollectionsOutlined } from "@mui/icons-material";
import MuiAlert from "@mui/material/Alert";
import { DataGrid, GridToolbar, GridActionsCellItem } from "@mui/x-data-grid";
import EditIcon from '@mui/icons-material/Edit';
import Table from "./Table";
import DocumentDataDialog from "./DocumentDataDialog";
import { useEffect, useState,forwardRef, useContext } from "react";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import { getDocData, getDocumentTypes, uploadFileResumable  } from "apis/documents";
import AddTypeDialog from "./AddTypeDialog";
import { doc, updateDoc,getDoc} from "firebase/firestore";
import { db,nextPushId} from "config/config";
import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils/index.js";
import { saveAs } from "file-saver";
import expressionParser from "docxtemplater/expressions";
import useCustomParams from "../shared/useCustomParams";
import { getDownloadURL, getMetadata, getStorage, ref,deleteObject } from "firebase/storage";


let searchTimeout = null;

function loadFile(url, callback) {
  return PizZipUtils.getBinaryContent(url, callback);
}




const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const Documents = ({
  documents,
  projectId,
  onSubmit,
  getProject,
  projectData,
  isInactive,
 contacts
}) => {
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [addTypeDialogOpen, setAddTypeDialogOpen] = useState(false);
  // const [newNote, setNewNote] = useState("");
  // const [searchValue, setSearchValue] = useState("");
  const [documentTypes, setDocumentTypes] = useState([]);
  // const [selectedDocument, setSelectedDocument] = useState({});
  const [filteredDocument, setFilteredDocument] = useState([]);
  const [loadingAnimation, setLoadingAnimation] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [deleteId, setDeleteId] = useState(null);


  const [feedbackMessage, setFeedbackMessage] = useState({
    open: false,
    message: '',
    severity: 'success'
  });




  useEffect(() => {
    (async () => {
      setLoadingAnimation(true);
      await getDocumentTypes(setDocumentTypes);
      setLoadingAnimation(false);
    })();
  }, []);


  useEffect(() => {
    // if (searchTimeout !== null) clearTimeout(searchTimeout);

    // searchTimeout = setTimeout(() => {
    //   setFilteredDocument(
    //     documents?.filter(
    //       (item) =>
    //         item?.name?.toLowerCase()?.includes(searchValue?.toLowerCase()) ||
    //         item?.documentTypeName
    //           ?.toLowerCase()
    //           ?.includes(searchValue?.toLowerCase()) ||
    //         item?.dateSent
    //           ?.toLowerCase()
    //           ?.includes(searchValue?.toLowerCase()) ||
    //         item?.recipient
    //           ?.toLowerCase()
    //           ?.includes(searchValue?.toLowerCase()) ||
    //         item?.dateReceived
    //           ?.toLowerCase()
    //           ?.includes(searchValue?.toLowerCase()) ||
    //         item?.comments?.toLowerCase()?.includes(searchValue?.toLowerCase()),
    //     ),
      
    //   );
    // }, 800);

 


  
    const fetchDocumentTypeName = async (documentTypeId) => {
      const documentTypeRef = doc(db, "documentTypes", documentTypeId); 
      const documentTypeSnap = await getDoc(documentTypeRef);
        return documentTypeSnap.data()?.name; 
     
    };
    const getRowsWithDocumentTypeNames = async () => {
      const alteredDocuments = await Promise.all(
      documents.map(async (document, index) => {
          const documentTypeName = await fetchDocumentTypeName(document.documentType); // Fetch the document type nam
    
          return {
            ...document,
            documentTypeName: documentTypeName, // Use fetched name here
          };
        })
      );
    
      return alteredDocuments ;
    };
    
    

    getRowsWithDocumentTypeNames().then((documents)=>{
      setFilteredDocument(documents)
    })


   
  }, [documents]);
// }, [documents, searchValue]);





const handleFileUpload = async (file, index) => {
  if (!file) return;

  if (file.type !== "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
    openFeedbackMessage("The only supported format is .docx", "error");
    return;
  }

  const fileRes = uploadFileResumable(`documentsCustomizedFiles/${nextPushId()()}`, file);
  fileRes.on(
    "state_changed",
    null,
    () => {},
    async () => {
      const documentsTmp = [...filteredDocument];
      documentsTmp[index] = {
        ...documentsTmp[index],
        fileUrl: fileRes?.snapshot?.metadata?.fullPath,
      };
      await handleDocumentData(documentsTmp);
      openFeedbackMessage("File uploaded successfully", "success");
    }
  );
};

// const handleDownload= async (path) => {
//   setLoadingAnimation(true);
//   const storage = getStorage();
//   const storageRef = ref(storage, path);
//   const link = await getDownloadURL(storageRef);
  
//   const linkInfo = await getMetadata(storageRef);

//   const aElement = document.createElement("a");
//   aElement.setAttribute("download", `${linkInfo?.customMetadata?.name}`);
//   aElement.href =link

//   aElement.click();
//   aElement.remove();

//   setLoadingAnimation(false);
// };

const handleDownload = async (path) => {
  setLoadingAnimation(true);
  const storage = getStorage();
  const storageRef = ref(storage, path);
  const link = await getDownloadURL(storageRef);

 
  const linkInfo = await getMetadata(storageRef);

  await generateDocument(link, linkInfo, async () => {
    const aElement = document.createElement("a");
    aElement.setAttribute("download", `${linkInfo?.customMetadata?.name}`);

    aElement.href = link;
    aElement.setAttribute("target", "_blank");
    aElement.click();
    aElement.remove();
  });

  setLoadingAnimation(false);
};



  // var decodedDocuments =
  //   documents && documents.length > 0 ? JSON.parse(documents) : [];

  const confirmDelete = async () => {
    // db.collection("filings").doc(itemToDelete.itemId).delete();
    // setItemToDelete({ itemId: "", identifier: "" });
    // setAddDialogOpen(false);
  };

  const generateDocument = async (fileUrl, fileInfo, cb) => {
    const client =
      projectData.client?.length > 0
        ? await getDocData(projectData.client, "contacts")
        : {};
    const building =
      projectData.building?.length > 0
        ? await getDocData(projectData.building, "buildings")
        : {};
    loadFile(fileUrl, async function (error, content) {
      if (error) {
        throw error;
      }

      try {
        const zip = new PizZip(content);

        const doc = new Docxtemplater(zip, {
          paragraphLoop: true,
          linebreaks: true,
          parser: expressionParser,
        });

        const todaysDate = new Date().toLocaleDateString("en-US", {
          year: "numeric",
          month: "short",
          day: "2-digit",
        });
        doc.render({
          jobID: projectData?.id || "--",
          clientName: `${client?.firstName || ""} ${client?.lastName || ""}`,
          clientFullName: client?.fullName || "",
          clientCompanyName: client?.businessName || "",
          clientAddress: client?.address1 || "",
          clientCity: client?.city || "",
          clientState: client?.state || "",
          clientZIP: client?.ZIP || "",
          projectLocation:
            `${building?.city || ""} ${building?.street || ""} ${building?.state || ""}` ||
            "",
          todaysDate,

          // floors:client?.,
          // tenantBusinessName, //commented
          // tenantAddress, //commented
          // tenantCity, //commented
          // tenantState, //commented
          // tenantZIP, //commented

          // projectDescription,
          // totalFee,
        });

        const out = doc.getZip().generate({
          type: "blob",
          mimeType:
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        }); //Output the document using Data-URI

        saveAs(out, `${fileInfo?.customMetadata?.name}`);
      } catch (error) {
        // console.log("error", error);
        cb();
      }
    });
  };
  
  

  const handleDocumentData = async (documents) => {
    setLoadingAnimation(true);
    const projectRef = doc(db, "projects", projectId);
    await updateDoc(projectRef, {
      documents,
    });
    setLoadingAnimation(false);
      // openFeedbackMessage('Document saved successfully!', 'success');
    return getProject();
  };


  const confirmDeleteDocument = (id) => {
    setDeleteId(id);
    setOpenConfirmDialog(true);
  };

  const editDocument = (index) => {
    setAddDialogOpen(`${index}`)
  };

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
    setDeleteId(null);
  };

  
  // const handleDeleteDocument = async () => {
  //   try {
  //     console.log(deleteId)
  //     // await firebase.firestore().collection("documentTypes").doc(deleteId).delete();
  //     handleDocumentData(
  //       filteredDocument.filter((item, index) => index !== Number(deleteId)),
  //     );
  
  //     setOpenConfirmDialog(false);
  //     setDeleteId(null);
  //     openFeedbackMessage('Document deleted successfully!', 'success');
  //   } catch (error) {
  //     console.error("Error deleting document type:", error);
  //   }
  // };



 
const handleDeleteDocument = async () => {
  try {
   
    // const documentToDelete = filteredDocument[deleteId];
    

    // const filePath = documentToDelete.documentTypeFileUrl; 

    // if (filePath) {
    //   const storage = getStorage();
    //   const fileRef = ref(storage, filePath);
    //   await deleteObject(fileRef);
    //   // console.log('File deleted from Firebase Storage');
    // }

    setLoadingAnimation(true);
    handleDocumentData(
      filteredDocument.filter((item, index) => index !== Number(deleteId)),
    );

    setOpenConfirmDialog(false);
    setDeleteId(null);
    setLoadingAnimation(false);
    openFeedbackMessage('Document deleted successfully!', 'success');
  } catch (error) {
    console.error("Error deleting document type and file:", error);
    openFeedbackMessage('Error deleting document!', 'error');
  }
};



  const openFeedbackMessage = (message, severity) => {
    setFeedbackMessage({ open: true, message, severity });
  };
  
  const handleCloseFeedbackMessage = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setFeedbackMessage({ ...feedbackMessage, open: false });
  };





  const columns = [
    { field: "documentTypeName", headerName: "Document Type", width: 200 },
    { field: "dateSent", headerName: "Date Sent", width: 150 },
    { field: "recipient", headerName: "Recipient", width: 150 },
    { field: "dateReceived", headerName: "Date Received", width: 150 },
    { field: "comments", headerName: "Comments", width: 200 },
    
  ];
  
  // Conditionally add the actions column
  if (!isInactive) {
    columns.push({
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <>
         <GridActionsCellItem
            icon={<Download />}
            onClick={(event) =>{
               event.stopPropagation() 
              handleDownload(params.row.fileUrl)}}
            label="Download"
          />

          {/* <GridActionsCellItem
            icon={<Upload />}
            component="label"
            label="Upload"
            onClick={(event) => {
               event.stopPropagation() 
              document.getElementById(`upload-input-${params.row.id}`).click()
            }}
          />
    
          <input
            id={`upload-input-${params.row.id}`}
            type="file"
            accept=".docx"
            style={{ display: "none" }}
            onChange={(e) =>{
              e.stopPropagation()
              handleFileUpload(e.target.files[0], params.row.id)}}
          /> */}

            <GridActionsCellItem
            icon={<EditIcon />}
            onClick={() => editDocument(params.row.id)}
            label="Edit"
          />
          <GridActionsCellItem
            icon={<Delete />}
            onClick={(event) => {
              event.stopPropagation(); // Prevent row click event
              confirmDeleteDocument(params.row.id)
            }}
            label="Delete"
          />
          
        </>
      ),
    });
  }



  // const rows = filteredDocument.map((document, index) => ({

  //   id: index,
  //   documentTypeName: document.documentTypeName,
  //   dateSent: document.dateSent,
  //   recipient: document.recipient,
  //   dateReceived: document.dateReceived,
  //   comments: document.comments,
  //   // documentTypeFileUrl: document.documentTypeFileUrl,
  //   fileUrl: document.fileUrl,
  // }));





  const rows = filteredDocument.map((document, index) => {
    const documentFile =
      document?.fileUrl ||
      document?.documentTypeFileUrl ||
      documentTypes?.find((item) => item?.id === document?.documentType)
        ?.fileUrl;


    return {
      id: index,
       documentTypeName: document.documentTypeName,
      dateSent: document.dateSent,
      // recipient: document.recipient,
      recipient:contacts.find((contact) => contact.id === document.recipient)?.fullName ||"",
      dateReceived: document.dateReceived,
      comments: document.comments,
      fileUrl: documentFile, 
    };
  });






  const { params, updateParams } = useCustomParams();

  const pageSize =
    Number(params.get("pageSize")) || 50,
    page = Number(params.get("page")) || 0;



  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={8} style={{ position: "sticky" }}>
          {/* <Typography align="left" variant="h4">
            Documents
          </Typography> */}
        </Grid>

        {/* <Grid item xs={12} md={6} lg={4}>
          <Paper
            component="form"
            onSubmit={(e) => e.preventDefault()}
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              width: "100%",
              marginLeft: "auto",
            }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search"
              value={searchValue}
              inputProps={{ "aria-label": "search" }}
              onInput={(e) => setSearchValue(e.target.value)}
            />
            {searchValue.length > 0 ? (
              <IconButton
                sx={{ p: "10px" }}
                aria-label="search"
                onClick={() => setSearchValue("")}
              >
                <Clear />
              </IconButton>
            ) : (
              <IconButton sx={{ p: "10px" }} aria-label="clear">
                <SearchIcon />
              </IconButton>
            )}
          </Paper>
        </Grid> */}
      </Grid>

      {/* <Table
        items={filteredDocument}
        documentTypes={documentTypes}
        handleDocumentData={handleDocumentData}
        setAddDialogOpen={setAddDialogOpen}
        setLoadingAnimation={setLoadingAnimation}
        generateDocument={generateDocument}
      /> */}


      <DataGrid rows={rows} columns={columns} pageSizeOptions={[10, 25, 50, 100]}
        pagination
        hideFooter
        onRowClick={(params) => editDocument(params.row.id)} 
        paginationModel={{ pageSize, page }}
        onPaginationModelChange={(params) => {
          if(filteredDocument.length > 0)
            updateParams({
              page: params.page,
              pageSize: params.pageSize,
            });
        }}
        rowCount={filteredDocument.length}
        initialState={{
          pagination: { paginationModel: { pageSize: 50 } },
        }}
        onFilterModelChange={(e) => {
          updateParams({
            pageSize: false,
            page: false,
            search: e?.items?.map((item) => item?.value).join("+"),
          });
        }}
        slots={{
          toolbar: GridToolbar,
        }} />
        

         {filteredDocument.length > 0  && (
        <TablePagination
          component="div"
          count={filteredDocument.length}
          page={page}
          onPageChange={(_, e) => {
            updateParams({
              page: e,
              pageIncreasing: `${e > Number(params.get("page"))}`,
            });
          }}
          rowsPerPageOptions={[10, 25, 50, 100]}
          rowsPerPage={pageSize}
          onRowsPerPageChange={(e) => {
            updateParams({ pageSize: `${e.target.value}`, page: false });
          }}
        />
      )}

      <Box
        sx={{
          position: "fixed",
          bottom: "1rem",
           left: "4rem",
          "& > :not(style)": { m: 1 },
        }}
      >
        {/* <Link to="/projects/new"> */}
        {!isInactive && (
          <Fab
            color="primary"
            aria-label="add"
            variant="extended"
            onClick={() => setAddDialogOpen(true)}
          >
            <AddIcon sx={{ mr: 1 }} />
            New
          </Fab>
        )}
      </Box>
      <CircularProgress
        {...loadingAnimation}
        style={{
          display: loadingAnimation ? "" : "none",
          position: "fixed",
          right: "10%",
          bottom: "10%",
          ...loadingAnimation?.style,
        }}
      />
      <DocumentDataDialog
        open={addDialogOpen}
        handleDocumentData={handleDocumentData}
        // documents={documents}
        documents={filteredDocument}
        setDialogOpen={setAddDialogOpen}
        documentTypes={documentTypes}
        openFeedbackMessage={openFeedbackMessage}
        isInactive={isInactive}
      />
      <AddTypeDialog
        open={addTypeDialogOpen}
        reloadData={() => getDocumentTypes(setDocumentTypes)}
        setDialogOpen={setAddTypeDialogOpen}
        documentTypes={documentTypes}
      />
       <Dialog open={openConfirmDialog} onClose={handleCloseConfirmDialog}>
        <DialogTitle>Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this document type? This action cannot
            be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteDocument} autoFocus variant="contained">
            Delete
          </Button>
          <Button onClick={handleCloseConfirmDialog} color="secondary" variant="contained">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      
      <Snackbar
      open={feedbackMessage.open}
      autoHideDuration={2000}
     onClose={handleCloseFeedbackMessage}
     >
     <Alert
    onClose={handleCloseFeedbackMessage}
    severity={feedbackMessage.severity}
    sx={{ width: "100%" }}
     >
    {feedbackMessage.message}
  </Alert>
</Snackbar>
    </>
  );
};

export default Documents;








