import { useCallback } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

export default function useCustomParams() {
  const navigate = useNavigate();

  const [params] = useSearchParams();

  const updateParams = useCallback(
    (obj) => {
      const newParams = new URLSearchParams(params);
      Object.entries(obj).map(([key, value]) =>
        // [key, value]
        !value ? newParams.delete(key) : newParams.set(key, value)
      );
      // params.set(name, value);
      // if (!value) params.delete(name);

      const paramsStr = newParams?.size > 0 ? `?${newParams.toString()}` : "";

      navigate(`${window.location.pathname}${paramsStr}`, { replace: true });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [params,navigate]
  );

  return { params, updateParams };
}
