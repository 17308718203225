import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import HighlightOffIcon from "@mui/icons-material/HighlightOff";

export default function CountCard({
  number,
  title,
  handleTableOpen,
  tableName,
  loading,
}) {
  return (
    <Card sx={{ backgroundColor: "#002977" }}>
      <CardContent>
        <Typography variant="h5" color="white" gutterBottom>
          {title}
        </Typography>

        <Typography
          variant="h1"
          color={number === 0 ? "#931621" : "#629677"}
          style={
            loading
              ? { color: "transparent", textShadow: "0 0 8px #931621" }
              : {}
          }
        >
          {number}
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          size="large"
          color="secondary"
          variant="contained"
          disabled={number === 0}
          onClick={() => handleTableOpen(title, tableName)}
        >
          View data
        </Button>
      </CardActions>
    </Card>
  );
}
