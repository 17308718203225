import React, { useEffect, useRef, useState } from 'react';
import { Modal, Box, Typography, Grid, Button, Card, CardContent, Paper, Divider } from '@mui/material';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { useReactToPrint } from 'react-to-print';
import { Close } from "@mui/icons-material";
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import PrintIcon from '@mui/icons-material/Print';
import Fab from "@mui/material/Fab";
import "./jobSheet.css";
import jsPDF from 'jspdf';
import dayjs from 'dayjs';



const JobSheetModal = ({ sheetData, open, onClose }) => {
    const componentRef = useRef();

    const printPdf = useReactToPrint({
        content: () => componentRef.current,
    });


    const formatCurrency = (value) => {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD'
        }).format(value);
    };

    const handleDownload = async () => {
        const doc = new jsPDF({
            unit: 'px',
            format: "A4",
            orientation: 'portrait',
            compress: true,
        });

        doc.setFont('helvetica', 'normal');

        await doc.html(componentRef.current, {
            width: doc.internal.pageSize.getWidth(),
            windowWidth: componentRef.current.scrollWidth,
            autoPaging: 'text',
            async callback(doc) {
                await doc.save('Job Sheet');
            },
            html2canvas: {
                scale: 0.5,
                letterRendering: true,
                useCORS: true,
                logging: false,
                allowTaint: false,
            }
        });
    };

    // const handleDownload = async () => {
    //     const customWidth = 1000;
    //     const customHeight = 1000 * 1.414; // Aspect ratio of A4 paper (1:1.414)

    //     const doc = new jsPDF({
    //         unit: 'px',
    //         format: [customWidth, customHeight], // Set custom page dimensions
    //         orientation: 'portrait',
    //         compress:true
    //     });

    //     // doc.setFont('Inter-Regular', 'normal');

    //     await doc.html(componentRef.current, {
    //         width: customWidth,
    //         windowWidth: customWidth, // Ensures full content width is captured
    //         margin: [10, 20, 30, 10],
    //         autoPaging: 'text',
    //         async callback(doc) {
    //             await doc.save('JobSheet');
    //         },
    //     });
    // };

    let arrOfStatus = [];
    if (sheetData?.status && sheetData?.status !== "") {
        arrOfStatus = JSON.parse(sheetData?.status);
    }

    return (
        <Modal open={open} onClose={onClose} aria-labelledby="job-sheet-modal-title" aria-describedby="job-sheet-modal-description">
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '80%',
                maxHeight: '80%',
                overflow: 'auto',
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                borderRadius: 2,
            }}>

                <Box ref={componentRef} className="printable-content" sx={{
                    width: '240mm',
                    padding: '10mm',
                    backgroundColor: '#f4f6f8',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                    boxShadow: 3,
                    gap: 2,
                    borderRadius: 2,
                }}>
                    <Typography variant="h4" component="h2" gutterBottom align="center" color="primary">
                        Job Sheet
                    </Typography>

                    <Divider sx={{ marginY: 2 }} />

                    <Grid container spacing={2}>
                        <Grid item className="avoid-break" xs={12} sm={6}>
                            <Card elevation={0} sx={{ marginBottom: 2 }}>
                                <CardContent>
                                    <Typography variant="subtitle1" color="text.secondary" gutterBottom>
                                        Job No:
                                    </Typography>
                                    <Typography variant="body1">{sheetData?.jobNo}</Typography>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item className="avoid-break" xs={12} sm={6}>
                            <Card elevation={0} sx={{ marginBottom: 2 }}>
                                <CardContent>
                                    <Typography variant="subtitle1" color="text.secondary" gutterBottom>
                                        Project Manager
                                    </Typography>
                                    <Typography variant="body1">{sheetData?.projectManger}</Typography>
                                </CardContent>
                            </Card>
                        </Grid>


                        <Grid item className="avoid-break" xs={12} sm={6}>
                            <Card elevation={0} sx={{ marginBottom: 2 }}>
                                <CardContent>
                                    <Typography variant="subtitle1" color="text.secondary" gutterBottom>
                                        Address
                                    </Typography>
                                    <Typography variant="body1">{sheetData?.address}</Typography>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item className="avoid-break" xs={12} sm={6}>
                            <Card elevation={0} sx={{ marginBottom: 2 }}>
                                <CardContent>
                                    <Typography variant="subtitle1" color="text.secondary" gutterBottom>
                                        Floor(s)
                                    </Typography>
                                    <Typography variant="body1">{sheetData?.floors}</Typography>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item className="avoid-break" xs={12} sm={12}>
                            <Card elevation={0} sx={{ marginBottom: 2 }}>
                                <CardContent>
                                    <Typography variant="subtitle1" color="text.secondary" gutterBottom>
                                        Client
                                    </Typography>
                                    <Typography variant="body1">{sheetData?.client}</Typography>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item className="avoid-break" xs={12} sm={12}>
                            <Card elevation={0} sx={{ marginBottom: 2 }}>
                                <CardContent>
                                    <Typography variant="subtitle1" color="text.secondary" gutterBottom>
                                        Owner
                                    </Typography>
                                    <Typography variant="body1">{sheetData?.owner}</Typography>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item className="avoid-break" xs={12} sm={12}>
                            <Card elevation={0} sx={{ marginBottom: 2 }}>
                                <CardContent>
                                    <Typography variant="subtitle1" color="text.secondary" gutterBottom>
                                        Tenant
                                    </Typography>
                                    <Typography variant="body1">{sheetData?.tenant} </Typography>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item className="avoid-break" xs={12} sm={12}>
                            <Card elevation={0} sx={{ marginBottom: 2 }}>
                                <CardContent>
                                    <Typography variant="subtitle1" color="text.secondary" gutterBottom>
                                        Contractor
                                    </Typography>
                                    <Typography variant="body1">{sheetData?.contractor}</Typography>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item className="avoid-break" xs={12} sm={12}>
                            <Card elevation={0} sx={{ marginBottom: 2 }}>
                                <CardContent sx={{ minHeight: "fit-content", }}>
                                    <Typography variant="subtitle1" color="text.secondary" gutterBottom>
                                        Work Description
                                    </Typography>
                                    <Typography variant="body1" sx={{ whiteSpace: "pre-line" }}>{sheetData?.workDescription}</Typography>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item className="avoid-break" xs={12} sm={6} md={4}>
                            <Card elevation={0} className="avoid-break" sx={{ marginBottom: 2 }}>
                                <CardContent>
                                    <Typography variant="subtitle1" color="text.secondary" gutterBottom>
                                        Block
                                    </Typography>
                                    <Typography variant="body1">{`${sheetData?.block}`}</Typography>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item className="avoid-break" xs={12} sm={6} md={4}>
                            <Card elevation={0} className="avoid-break" sx={{ marginBottom: 2 }}>
                                <CardContent>
                                    <Typography variant="subtitle1" color="text.secondary" gutterBottom>
                                        Lot(s)
                                    </Typography>
                                    <Typography variant="body1">{`${sheetData?.lot}`}</Typography>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item className="avoid-break" xs={12} sm={6} md={4}>
                            <Card elevation={0} className="avoid-break" sx={{ marginBottom: 2 }}>
                                <CardContent>
                                    <Typography variant="subtitle1" color="text.secondary" gutterBottom>
                                        BIN
                                    </Typography>
                                    <Typography variant="body1">{`${sheetData?.bin}`}</Typography>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item className="avoid-break" xs={12} sm={6} md={4}>
                            <Card elevation={0} className="avoid-break" sx={{ marginBottom: 2 }}>
                                <CardContent>
                                    <Typography variant="subtitle1" color="text.secondary" gutterBottom>
                                        House Number
                                    </Typography>
                                    <Typography variant="body1">{`${sheetData?.houseNumber}`}</Typography>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item className="avoid-break" xs={12} sm={6} md={4}>
                            <Card elevation={0} className="avoid-break" sx={{ marginBottom: 2 }}>
                                <CardContent>
                                    <Typography variant="subtitle1" color="text.secondary" gutterBottom>
                                        CB#
                                    </Typography>
                                    <Typography variant="body1">{`${sheetData?.cb}`}</Typography>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item className="avoid-break" xs={12} sm={6} md={4}>
                            <Card elevation={0} className="avoid-break" sx={{ marginBottom: 2 }}>
                                <CardContent>
                                    <Typography variant="subtitle1" color="text.secondary" gutterBottom>
                                        Borough
                                    </Typography>
                                    <Typography variant="body1">{`${sheetData?.borough}`}</Typography>
                                </CardContent>
                            </Card>
                        </Grid>


                    </Grid>

                    <Divider sx={{ marginY: 2 }} />

                    {sheetData?.workTypes.length > 0 &&
                        <Box className="avoid-break" sx={{ marginY: 2 }}>
                            <Typography variant="h6" color="text.primary" gutterBottom>
                                Work Types
                            </Typography>
                            {sheetData?.workTypes.map((workType, index) => (
                                <Paper className="avoid-break" key={index} elevation={1} sx={{ padding: 2, borderRadius: 2, marginTop: 2, backgroundColor: '#fff' }}>

                                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                        <Typography variant="subtitle1" color="primary" gutterBottom>
                                            Work Type: {workType.workType || "N/A"}
                                        </Typography>
                                        <Typography variant="subtitle1" color="primary" gutterBottom>
                                            Estimated Cost: {workType.estimatedCost ? formatCurrency(workType.estimatedCost) : "0"}
                                        </Typography>
                                    </div>

                                    <Typography variant="body2" color="text.secondary" gutterBottom>
                                        Applicant: {workType.applicantDetails?.fullName || "N/A"}, {workType.applicantDetails?.businessName || "N/A"}
                                    </Typography>

                                    <Typography variant="body2" color="text.secondary" gutterBottom>
                                        Address: {workType.applicantDetails?.address1 || "N/A"}, {workType.applicantDetails?.city || "N/A"}, {workType.applicantDetails?.state || "N/A"}
                                    </Typography>

                                    {workType.applicantDetails?.phone && (
                                        <Typography variant="body2" color="text.secondary" gutterBottom>
                                            Tel: {workType.applicantDetails.phone}
                                        </Typography>
                                    )}

                                    {workType.applicantDetails?.licenseType && workType.applicantDetails?.licenseNumber && (
                                        <Typography variant="body2" color="text.secondary" gutterBottom>
                                            {workType.applicantDetails.licenseType} Lic. No: {workType.applicantDetails.licenseNumber}
                                        </Typography>
                                    )}

                                    {workType.applicantDetails?.email && (
                                        <Typography variant="body2" color="text.secondary" gutterBottom>
                                            Email: {workType.applicantDetails.email}
                                        </Typography>
                                    )}

                                </Paper>
                            ))}

                        </Box>
                    }



                    {arrOfStatus?.length > 0 &&
                        <div style={{ marginTop: 2 }} className="avoid-break">
                            <h2 className="text-2xl font-bold text-gray-800 mb-6">Status</h2>
                            <div>
                                <Timeline className="p-0" sx={{
                                    [`& .${timelineItemClasses.root}:before`]: {
                                        flex: 0,
                                        padding: 0,
                                    },
                                }}>
                                    {
                                        arrOfStatus?.map((ele, index) => (
                                            <TimelineItem key={index} className="avoid-break">
                                                <TimelineSeparator>
                                                    <TimelineDot color="primary" />
                                                    <TimelineConnector />
                                                </TimelineSeparator>
                                                <TimelineContent>
                                                    <Paper elevation={1}>
                                                        <div style={{ padding: "5px" }}>
                                                            <span>{dayjs(ele.timestamp).format('YYYY-MM-DD')}</span>
                                                            <p style={{ marginTop: "10px", marginBottom: "0" }}>
                                                                {ele.note}
                                                            </p>
                                                        </div>
                                                    </Paper>
                                                </TimelineContent>
                                            </TimelineItem>
                                        ))
                                    }

                                </Timeline>
                            </div>
                        </div>
                    }


                </Box>

                <div style={{ marginTop: "25px" }}>

                    <Fab
                        sx={{ mr: 2 }}
                        color="primary"
                        aria-label="add"
                        variant="extended"
                        onClick={printPdf}
                    >
                        <PrintIcon sx={{ mr: 1 }} />
                        Print
                    </Fab>

                    <Fab
                        sx={{ mr: 2 }}
                        color="primary"
                        aria-label="add"
                        variant="extended"
                        onClick={handleDownload}
                    >
                        <DownloadForOfflineIcon sx={{ mr: 1 }} />
                        Download As PDF
                    </Fab>
                    <Fab
                        sx={{ mr: 2 }}
                        color="secondary"
                        aria-label="add"
                        variant="extended"
                        onClick={onClose}
                    >
                        <Close sx={{ mr: 1 }} />
                        Close
                    </Fab>
                </div>

            </Box>
        </Modal >

    );
};

export default JobSheetModal;