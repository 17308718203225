import React from "react";

import { Box, Link, Fab, DialogTitle } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import MenuItem from "@mui/material/MenuItem";
import Slide from "@mui/material/Slide";
import Grid from "@mui/material/Grid";
import AddIcon from "@mui/icons-material/Add";
import TextField from "@mui/material/TextField";
import { Save, Close } from "@mui/icons-material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function NewToDo(props) {
  const [open, setOpen] = React.useState(false);
  const [isDirty, setIsDirty] = React.useState(false);

  const [values, setValues] = React.useState({ due: "" });

  const handleChange = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value });
    setIsDirty(true);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Box
        sx={{
          position: "fixed",
          bottom: "1rem",
          right: "1rem",
          "& > :not(style)": { m: 1 },
        }}
      >
        {!props.isInactive && (
          <Fab
            color="primary"
            aria-label="add"
            variant="extended"
            onClick={handleClickOpen}
            disabled={props.isInactive}
          >
            <AddIcon sx={{ mr: 1 }} />
            New
          </Fab>
        )}
      </Box>
      <Dialog
        maxWidth={"xl"}
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <DialogTitle sx={{ backgroundColor: "#002977", color: "white" }}>
          {values.title}
        </DialogTitle>
        <form noValidate autoComplete="off" style={{ padding: "2rem" }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <TextField
                id="title"
                label="Title"
                value={values.title}
                onChange={handleChange("title")}
                margin="normal"
                variant="outlined"
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <TextField
                id="description"
                label="Description"
                value={values.description}
                onChange={handleChange("description")}
                margin="normal"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                id="details"
                label="Details"
                value={values.details}
                onChange={handleChange("details")}
                margin="normal"
                variant="outlined"
                fullWidth
                multiline
                rows="10"
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                id="assignedTo"
                label="Assigned to"
                value={values.assignedTo}
                onChange={handleChange("assignedTo")}
                margin="normal"
                variant="outlined"
                fullWidth
                select
              >
                {props.contacts &&
                  props.contacts.length !== 0 &&
                  props.contacts.map((contact) => (
                    <MenuItem value={contact.fullName}>
                      {contact.fullName}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                id="due"
                label="Due Date"
                value={values.due}
                onChange={handleChange("due")}
                margin="normal"
                variant="outlined"
                fullWidth
                type="date"
              />
            </Grid>
          </Grid>
        </form>
        <Box
          sx={{
            position: "fixed",
            bottom: "1rem",
            right: "1rem",
            "& > :not(style)": { m: 1 },
          }}
        >
          {!props.isInactive && (
            <Fab
              color="primary"
              aria-label="add"
              variant="extended"
              onClick={() => props.addNewCard(values)}
              disabled={!isDirty || props.isInactive}
            >
              <Save sx={{ mr: 1 }} />
              Save
            </Fab>
          )}
          <Fab
            color="secondary"
            aria-label="add"
            variant="extended"
            onClick={handleClose}
          >
            <Close sx={{ mr: 1 }} />
            Close
          </Fab>
        </Box>
      </Dialog>
    </div>
  );
}
