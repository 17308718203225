import { useContext, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { TextField } from "@mui/material";
import { addDocumentType, editDocumentType, uploadFile } from "apis/documents";
import { Context } from "context/Wrapper";

const AddTypeDialog = ({ open, reloadData, setDialogOpen, documentTypes }) => {
  const context = useContext(Context);
  const { groupId, userId } = context?.state?.userProfile || {};

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(false);
  }, []);

  const handleClose = () => {
    setDialogOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const data = new FormData(e.target);
    const dataObj = Object.fromEntries(data.entries());
    const file = dataObj?.file?.[0] || dataObj?.file;

    delete dataObj.file;
    
    if (groupId) dataObj.groupId = groupId;
    dataObj.userId = userId;

    const res = await addDocumentType(dataObj);
    const fileRes = await uploadFile(`documentTypesFiles/${res.id}`, file);
    await editDocumentType({
      data: { fileUrl: fileRes.metadata.fullPath },
      id: res.id,
    });
    setLoading(false);
    reloadData();
    e.target.reset();
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle id="alert-dialog-title">Add Document Type</DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            width: "400px",
            paddingBlock: "10px !important",
          }}
        >
          <TextField
            label={"Name"}
            type="text"
            name="name"
            onChange={(event) => {
              // handleChangeNewFormNote(event);
            }}
            fullWidth
            required
          />
          <TextField
            label={"File"}
            InputLabelProps={{ shrink: true }}
            type="file"
            name="file"
            onChange={(event) => {
              // handleChangeNewFormNote(event);
            }}
            fullWidth
            required
          />
        </DialogContent>
        <DialogActions>
          <Button type="button" variant="contained" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            type="submit"
            // onClick={confirmDelete}
            variant="contained"
            color="secondary"
            disabled={loading}
          >
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddTypeDialog;
