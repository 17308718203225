import * as React from "react";

const T = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={1333.333}
    width={1333.333}
    xmlSpace="preserve"
    viewBox="0 0 1333.33 1333.33"
    {...props}
  >
    <path
      style={{
        fill: "#fff",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="m0 0-62.857-62.857v-250L0-375.714h250v-561.428L311.429-1000h250l62.857 62.858-1.429 561.428h251.429l62.857 62.857v250L874.286 0Z"
      transform="matrix(1.33333 0 0 -1.33333 83.81 0)"
    />
    q
  </svg>
);

export default T;
