import React, { useState, useEffect, useContext } from "react";
import { Typography, Select, MenuItem, InputLabel, Snackbar, TextField } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Fade from "@mui/material/Fade";
import Fab from "@mui/material/Fab";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import AddIcon from "@mui/icons-material/Add";
import Clear from "@mui/icons-material/Clear";
import { DeleteDialog } from "../components/DeleteDialog";
import { Link } from "react-router-dom";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import jsPDF from 'jspdf';
import { Context } from "context/Wrapper";
import { db } from "config/config";
import { doc, getDoc, where, or, and, collection, query, getDocs } from "firebase/firestore";
import { useAdvancedDataListener } from "apis/dataSnapshots";
import AdvancedTable from "components/AdvancedTable";
import useCustomParams from "components/shared/useCustomParams";
import tableConfig from "config/advancedTableColumnsControl.json";
import dayjs from 'dayjs';
import InfiniteScrollSelect from "components/shared/InfiniteScrollSelect";
import CircularProgress from '@mui/material/CircularProgress';


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const searchLimit = tableConfig?.searchMinLength;

let filterSearchedTimeout = null;

export const Invoice = (props) => {
    const context = useContext(Context);
    const { params, updateParams } = useCustomParams();

    const { groupId, isPro } = context?.state?.userProfile || {};
    const user = context?.state?.userProfile || {},
        { showAllDataFlag } = context?.state || {};

    const [invoices, setInvoices] = useState([]);

    const [deleteOpen, setDeleteOpen] = useState(false);

    const [itemToDelete, setItemToDelete] = useState({
        itemId: "",
        identifier: "",
    });

    const [filteredInvoices, setFilteredInvoices] = useState([]);

    const [searchValue, setSearchValue] = useState("");
    const [dateRange, setDateRange] = useState("");
    const [customStartDate, setCustomStartDate] = useState(null);
    const [customEndDate, setCustomEndDate] = useState(null);
    const [open, setOpen] = useState(false);
    const [deletedOpen, setdeletedOpen] = useState(false);
    const [reportType, setReportType] = useState(''); // Job or Customer
    const [selectedOption, setSelectedOption] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [notFoundInv, setNotFoundInv] = useState(false);

    const { data, count } = useAdvancedDataListener({
        user,
        // conditions,
        // setLastRef: setLastVisible,
        condition: groupId && isPro && showAllDataFlag,
        collectionName: "invoices",
        // rowsLimit,
        // params,
    });

    const [invoiceCount, setInvoiceCount] = useState(count);


    useEffect(() => {
        updateParams({
            page: "",
            search: "",
            pageIncreasing: "",
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setInvoices(data);
        setInvoiceCount(count);
    }, [data, count]);


    useEffect(() => {
        if (
            (searchValue?.length < searchLimit && searchValue?.length !== 0) ||
            !searchValue
        ) {
            if (searchValue?.length === 0) {
                updateParams({ search: "", pageSize: params.get("pageSize") });
            }
            return;
        }
        if (filterSearchedTimeout !== null) clearTimeout(filterSearchedTimeout);

        filterSearchedTimeout = setTimeout(() => {
            updateParams({ search: searchValue, pageSize: params.get("pageSize") });
            const newArr = invoices
                .filter(
                    (item) =>
                        item.balanceDue?.toString().toLowerCase().includes(searchValue.toLowerCase()) ||
                        item.totalPayments?.toString().toLowerCase().includes(searchValue.toLowerCase()) ||
                        item.adjustments?.toString().toLowerCase().includes(searchValue.toLowerCase()) ||
                        item.invoiceNumber?.toString().toLowerCase().includes(searchValue.toLowerCase()) ||
                        item.totalCharges?.toString().toLowerCase().includes(searchValue.toLowerCase()) ||
                        item.jobName?.toString().toLowerCase().includes(searchValue.toLowerCase()) ||
                        item.customerName?.toString().toLowerCase().includes(searchValue.toLowerCase()) ||
                        item.dueDate?.toString().toLowerCase().includes(searchValue.toLowerCase())
                )
                .map((item) => {
                    // Step 3: Highlight search results if search is applied
                    let newbalanceDue =
                        item.balanceDue &&
                        item.balanceDue.toString().replace(
                            new RegExp(searchValue, "gi"),
                            (match) =>
                                `<mark style="background: #2769AA; color: white;">${match}</mark>`
                        );
                    let newtotalPayments =
                        item.totalPayments &&
                        item.totalPayments.toString().replace(
                            new RegExp(searchValue, "gi"),
                            (match) =>
                                `<mark style="background: #2769AA; color: white;">${match}</mark>`
                        );
                    let newadjustments =
                        item.adjustments &&
                        item.adjustments.toString().replace(
                            new RegExp(searchValue, "gi"),
                            (match) =>
                                `<mark style="background: #2769AA; color: white;">${match}</mark>`
                        );
                    let newinvoiceNumber =
                        item.invoiceNumber &&
                        item.invoiceNumber.toString().replace(
                            new RegExp(searchValue, "gi"),
                            (match) =>
                                `<mark style="background: #2769AA; color: white;">${match}</mark>`
                        );
                    let newtotalCharges =
                        item.totalCharges &&
                        item.totalCharges.toString().replace(
                            new RegExp(searchValue, "gi"),
                            (match) =>
                                `<mark style="background: #2769AA; color: white;">${match}</mark>`
                        );
                    let newjobName =
                        item.jobName &&
                        item.jobName.toString().replace(
                            new RegExp(searchValue, "gi"),
                            (match) =>
                                `<mark style="background: #2769AA; color: white;">${match}</mark>`
                        );
                    let newcustomerName =
                        item.customerName &&
                        item.customerName.toString().replace(
                            new RegExp(searchValue, "gi"),
                            (match) =>
                                `<mark style="background: #2769AA; color: white;">${match}</mark>`
                        );
                    let newdueDate =
                        item.dueDate &&
                        item.dueDate.toString().replace(
                            new RegExp(searchValue, "gi"),
                            (match) =>
                                `<mark style="background: #2769AA; color: white;">${match}</mark>`
                        );
                    return {
                        ...item,
                        balanceDue: newbalanceDue,
                        totalPayments: newtotalPayments,
                        adjustments: newadjustments,
                        invoiceNumber: newinvoiceNumber,
                        totalCharges: newtotalCharges,
                        jobName: newjobName,
                        customerName: newcustomerName,
                        dueDate: newdueDate,
                    };
                });

            setFilteredInvoices(newArr);
        }, 500);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoices, searchValue]);


    const handleDateRangeChange = (e) => {
        const value = e.target.value;
        setDateRange(value);
        if (value !== "other") {
            setCustomStartDate(null);
            setCustomEndDate(null);
        }
    };

    const handleStartDateChange = (e) => {
        setCustomStartDate(e.target.value);
    };

    const handleEndDateChange = (e) => {
        setCustomEndDate(e.target.value);
    };

    const handleSearch = (e) => {
        const value = e.target.value;
        setSearchValue(value);
    };

    const handleDelete = (id, identifier, row) => {
        setItemToDelete({ itemId: id, identifier: row.invoiceNumber });
        setDeleteOpen(true);
        // invoiceCollection = invoiceCollection.filter((item)=>item.id !== id);
    };

    const confirmDelete = async () => {
        db.collection("invoices").doc(itemToDelete.itemId).delete();
        if (searchCheck) {
            let newArr = filteredInvoices.filter((item) => item.id !== itemToDelete.itemId);
            setFilteredInvoices(newArr)
        } else {
            let newArr = invoices.filter((item) => item.id !== itemToDelete.itemId);
            setInvoices(newArr)
        }

        setInvoiceCount((prev) => prev - 1);
        setItemToDelete({ itemId: "", identifier: "" });
        setDeleteOpen(false);
        setdeletedOpen(true);
        // navigate("/invoices");
    };


    const handleNotFoundOpen = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setNotFoundInv(false);
    };

    const searchCheck = searchValue.length >= searchLimit;
    // let invoiceCollection = searchCheck || dateRange ? filteredInvoices : invoices;

    const getDocumentById = async (collectionName, docId) => {
        try {
            const docRef = doc(db, collectionName, docId);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                // console.log("Document data:", docSnap.data());
                return docSnap.data();  // Returns document data
            } else {
                console.log("No such document!");
            }
        } catch (error) {
            console.error("Error getting document:", error);
        }
    };


const formatCurrency = (value) => {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD'
        }).format(value);
    };


    const allInvoices = async (reportType, selectedOption) => {
        const invoicesCollection = collection(db, "invoices");
        let queryConditions;
        // Set up conditions based on groupId, isPro, and showAllDataFlag
        if (groupId && isPro && showAllDataFlag) {
            // Combine with and() and or() as per Firestore's requirements
            queryConditions = and(
                or(
                    where("groupId", "==", groupId),
                    where("userId", "==", user.userId)
                ),
                reportType === "customer"
                    ? where("invoiceFor", "==", selectedOption)
                    : where("invoiceJob", "==", selectedOption)
            );
        } else {
            queryConditions = and(
                where("userId", "==", user.userId),
                reportType === "customer"
                    ? where("invoiceFor", "==", selectedOption)
                    : where("invoiceJob", "==", selectedOption)
            );
        }

        const invoicesQuery = query(invoicesCollection, queryConditions);
        const querySnapshot = await getDocs(invoicesQuery);
        const data = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        return data;
    };


    // Function to handle custom export
    const handleCustomExport = async () => {
        let filteredByDateRange = await allInvoices(reportType, selectedOption);
        // Step 1: Apply date range filtering only if dateRange is not null
        if (dateRange !== "" && dateRange !== "other") {
            const today = new Date();
            const pastDate = new Date(today.setDate(today.getDate() - dateRange)); // Use dateRange for filtering days (e.g., 30, 60, 90 days)

            filteredByDateRange = filteredByDateRange.filter(item => {
                const invoiceDate = new Date(item.billingDate); // Replace 'billingDate' with the relevant date field
                return invoiceDate >= pastDate;
            });
        } else if (dateRange === "other" && customStartDate && customEndDate) {
            const startDate = new Date(customStartDate);
            const endDate = new Date(customEndDate);

            filteredByDateRange = filteredByDateRange.filter(item => {
                const invoiceDate = new Date(item.billingDate); // Replace 'billingDate' with the relevant date field
                return invoiceDate >= startDate && invoiceDate <= endDate;
            });
        }

        if (filteredByDateRange.length < 1) {
            setNotFoundInv(true);
            return;
        }


        setIsLoading(true);
        const pdfDoc = new jsPDF();

        // // Statement header
        pdfDoc.setFontSize(18);
        pdfDoc.text("Statement of Account", 105, 10, null, null, 'center');

        // Customer information (for example, based on the provided PDF sample)
        pdfDoc.setFontSize(12);
        pdfDoc.text(`Generated on ${dayjs().format('YYYY-MM-DD')}`, 14, 20);
        pdfDoc.setFont("helvetica", "bold"); // Set font to bold

        if (reportType === "customer" && selectedOption !== "") {
            const contact = await getDocumentById("contacts", selectedOption, ["fullName", "businessName", "address1", "email", "phone"]);
            let arrOfData = [contact?.fullName, contact?.businessName, contact?.address1, contact?.email, contact?.phone,]
            let yAxi = 30;
            for (let i = 0; i < arrOfData.length; i++) {
                if (arrOfData[i]) {
                    if (i == 2) {
                        yAxi = yAxi + 6;
                        pdfDoc.text(arrOfData[i], 14, yAxi);
                        yAxi = yAxi + 6;
                        pdfDoc.text(`${contact?.city ? contact?.city + "," : ""} ${contact?.state ? contact?.state : ""} ${contact?.ZIP ? contact?.ZIP : ""}`, 14, yAxi);
                    } else if (arrOfData[i] == contact?.email) {
                        yAxi = yAxi + 6;
                        pdfDoc.text(`E-mail: ${arrOfData[i]}`, 14, yAxi);
                    } else if (arrOfData[i] == contact?.phone) {
                        yAxi = yAxi + 6;
                        pdfDoc.text(`Phone: ${arrOfData[i]}`, 14, yAxi);
                    } else {
                        yAxi = yAxi + 6;
                        pdfDoc.text(arrOfData[i], 14, yAxi);
                    }
                }
            };

        } else {
            const project = await getDocumentById("projects", selectedOption, ["customerReference", "DOBNumber", "building", "client"]);
            const building = await getDocumentById("buildings", project.building, ["houseNumber", "streetName", "city", "state", "ZIP"]);
            const contact = await getDocumentById("contacts", project.client, ["businessName"]);

            project?.customerReference && pdfDoc.text(`Job ID: ${project?.customerReference}`, 14, 30);

            pdfDoc.text(`Building Address: ${building?.houseNumber ? building?.houseNumber : ""} ${building?.streetName ? building?.streetName + "," : ""}`, 14, 36);

            pdfDoc.text(`${building?.city ? building?.city + "," : ""} ${building?.state ? building?.state + "," : ""} ${building?.ZIP ? building?.ZIP : ""}`, 14, 42);

            contact?.businessName && pdfDoc.text(`Client: ${contact?.businessName}`, 14, 48);
            project?.DOBNumber && pdfDoc.text(`DOB Number: ${project?.DOBNumber}`, 14, 54);
        }


        pdfDoc.setFont("helvetica", "normal");

        // Table Header (customized colors)
        const tableColumnHeaders = ["Invoice #",
            "Date", "Due Date", reportType === "customer" ? "Job" : "Customer", "Charges", "Adjustments", "Payments", "Balance Due"
        ];

        // Calculate total balance
        let totalBalance = filteredByDateRange.reduce((sum, row) => sum + (row.balanceDue || 0), 0);


        // Add a line for total balance
        const pageWidth = pdfDoc.internal.pageSize.getWidth(); // Get page width
        const totalBalanceX = pageWidth - 14;
        pdfDoc.text(`The total balance due is:`, totalBalanceX, 30, { align: "right" });
        pdfDoc.setFont("helvetica", "bold"); // Set font to bold
        pdfDoc.text(formatCurrency(totalBalance), totalBalanceX - 25, 36, { align: "right" });

        pdfDoc.setFont("helvetica", "normal");
        pdfDoc.text(`Please remit this sum at the`, totalBalanceX, 42, { align: "right" });
        pdfDoc.text(`earliest possible convenience.`, totalBalanceX + 4, 48, { align: "right" });

        // Customize your table rows here
        const tableRows = filteredByDateRange
            .sort((a, b) => new Date(b.billingDate) - new Date(a.billingDate)) // Sort by billingDate 
            .map((row) => [
                row.invoiceNumber,
                dayjs(row.billingDate).format('YYYY-MM-DD'),
                dayjs(row.dueDate).format('YYYY-MM-DD'),
                (reportType === "customer" ? row.jobName : row.customerName ),
                formatCurrency(row.totalCharges),
                formatCurrency(row.adjustments),
                formatCurrency(row.totalPayments),
                formatCurrency(row.balanceDue),
            ]);


        // Set table colors and add data
        pdfDoc.autoTable({
            head: [tableColumnHeaders],
            body: tableRows,
            startY: 80,
            theme: 'grid',  // Adds grid lines to table
            styles: {
                fillColor: [240, 240, 240], // Light gray background for table rows
                textColor: [0, 0, 0], // Black text
                fontSize: 10,
            },
            headStyles: {
                fillColor: [0, 0, 0],
                textColor: [255, 255, 255], // White text for headers
                fontStyle: 'bold',
            },
        });

        // Retrieve the final Y position after the table
        const finalY = pdfDoc.previousAutoTable.finalY || 80;

        // Add final notes or generated date below the table
        pdfDoc.setTextColor(0, 0, 0);  // Reset text color to black
        pdfDoc.text(`Balance Due Over Last 90 Days:  ${dateRange == 90 ? formatCurrency(totalBalance) : "<none>"}`, totalBalanceX, finalY + 30, { align: "right" });
        pdfDoc.text(`Balance Due Over Last 60 Days:  ${dateRange == 60 ? formatCurrency(totalBalance) : "<none>"} `, totalBalanceX, finalY + 40, { align: "right" });
        pdfDoc.text(`Balance Due Over Last 30 Days:  ${dateRange == 30 ? formatCurrency(totalBalance) : "<none>"}`, totalBalanceX, finalY + 50, { align: "right" });


        if (dateRange != 30 && dateRange != 60 && dateRange != 90 && dateRange != "" && dateRange != "other") {
            pdfDoc.text(`Balance Due Over Last ${dateRange} Days: ${formatCurrency(totalBalance)}`, totalBalanceX, finalY + 60, { align: "right" });
            pdfDoc.setFont("helvetica", "bold"); // Set font to bold
            pdfDoc.text(`Balance Due:  ${formatCurrency(totalBalance)}`, totalBalanceX, finalY + 70, { align: "right" });
        } else if (dateRange === "other") {
            pdfDoc.text(`Balance Due Between (${customStartDate}) And (${customEndDate}) : ${formatCurrency(totalBalance)}`, totalBalanceX, finalY + 60, { align: "right" });
            pdfDoc.setFont("helvetica", "bold"); // Set font to bold
            pdfDoc.text(`Balance Due:  ${formatCurrency(totalBalance)}`, totalBalanceX, finalY + 70, { align: "right" });
        }
        else {
            pdfDoc.setFont("helvetica", "bold"); // Set font to bold
            pdfDoc.text(`Balance Due:  ${formatCurrency(totalBalance)}`, totalBalanceX, finalY + 60, { align: "right" });
        }



        // Save the PDF
        pdfDoc.save(`invoice-Report-${new Date().toLocaleDateString()}.pdf`);

        setIsLoading(false);
        setOpen(false);
        setSelectedOption("")
        setReportType("")
        setCustomEndDate("")
        setCustomStartDate("")
        setDateRange("")
    };


    const handleReportTypeChange = (event) => {
        setReportType(event.target.value);
        setSelectedOption(''); // Reset selection when changing report type
    };

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            setOpen(false);
        }
    };

    const handleCloseDeleted = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setdeletedOpen(false);
    };

    return (
        <>
            <Fade in={true}>
                <div>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6} lg={8} style={{ position: "sticky" }}>
                            <Typography align="left" variant="h4">
                                INVOICES
                            </Typography>
                        </Grid>

                        <Grid item xs={12} md={6} lg={4}>
                            <Paper
                                component="form"
                                onSubmit={(e) => e.preventDefault()}
                                sx={{
                                    p: "2px 4px",
                                    display: "flex",
                                    alignItems: "center",
                                    width: "100%",
                                    marginLeft: "auto",
                                }}
                            >
                                <InputBase
                                    sx={{ ml: 1, flex: 1 }}
                                    placeholder="Search"
                                    value={searchValue}
                                    inputProps={{ "aria-label": "search" }}
                                    onInput={handleSearch}
                                />
                                {searchCheck ? (
                                    <IconButton
                                        sx={{ p: "10px" }}
                                        aria-label="search"
                                        onClick={() => {
                                            setSearchValue("");
                                            updateParams({ search: "" });
                                        }}
                                    >
                                        <Clear />
                                    </IconButton>
                                ) : (
                                    <IconButton sx={{ p: "10px" }} aria-label="clear">
                                        <SearchIcon />
                                    </IconButton>
                                )}
                            </Paper>
                        </Grid>
                    </Grid>


                    <AdvancedTable
                        data={searchCheck ? filteredInvoices : invoices}
                        collectionName="invoices"
                        columnsRaw={[
                            // {
                            // field: "id",
                            // headerName: "ID",
                            // },
                            {
                                field: "billingDate",
                                headerName: "Date",
                                type: "date",
                                sortable: true, // Enable sorting
                                valueGetter: (value) => {
                                    return value ? new Date(value) : null;
                                }, // Transform to Date object
                                sortComparator: (a, b) => b - a, // Sort in descending order
                                renderCell: ({ row }) => (
                                    <div dangerouslySetInnerHTML={{ __html: dayjs(row.billingDate).format('YYYY-MM-DD') }}></div>
                                ),
                            },
                            {
                                field: "dueDate",
                                headerName: "Due Date",
                                type: "date",
                                sortable: true, // Enable sorting
                                valueGetter: (val) => new Date(val), // Ensure sorting by Date
                                renderCell: ({ row }) => (
                                    <div dangerouslySetInnerHTML={{ __html: dayjs(row.dueDate).format('YYYY-MM-DD') }}></div>
                                ),
                            },
                            {
                                field: "invoiceNumber",
                                headerName: "Invoice Number",
                                renderCell: ({ row }) => (
                                    <div dangerouslySetInnerHTML={{ __html: row.invoiceNumber }}></div>
                                ),
                            },
                            {
                                field: "jobName",
                                headerName: "Job",
                                renderCell: ({ row }) => (
                                    <div dangerouslySetInnerHTML={{ __html: row.jobName }}></div>
                                ),
                            },
                            {
                                field: "customerName",
                                headerName: "Customer",
                                renderCell: ({ row }) => (
                                    <div dangerouslySetInnerHTML={{ __html: row.customerName }}></div>
                                ),
                            },
                            {
                                field: "totalCharges",
                                headerName: "Charges",
                                renderCell: ({ row }) => (
                                    <div
                                        dangerouslySetInnerHTML={{ __html: `$${row.totalCharges}` }}
                                    ></div>
                                ),
                            },
                            {
                                field: "adjustments",
                                headerName: "Adjustments",
                                renderCell: ({ row }) => (
                                    <div
                                        dangerouslySetInnerHTML={{ __html: `$${row.adjustments}` }}
                                    ></div>
                                ),
                            },
                            {
                                field: "totalPayments",
                                headerName: "Total Payments",
                                renderCell: ({ row }) => (
                                    <div
                                        dangerouslySetInnerHTML={{ __html: `$${row.totalPayments}` }}
                                    ></div>
                                ),
                            },
                            {
                                field: "balanceDue",
                                headerName: "Balance Due",
                                renderCell: ({ row }) => (
                                    <div
                                        dangerouslySetInnerHTML={{ __html: `$${row.balanceDue}` }}
                                    ></div>
                                ),
                            },
                        ]}
                        rowCount={searchCheck ? filteredInvoices?.length : invoiceCount}
                        // rowsLimit={rowsLimit}
                        handleDelete={handleDelete}
                        dateRange={dateRange}
                    />

                    {user.subscription !== "inactive" &&
                        <Box
                            sx={{
                                position: "fixed",
                                bottom: "1rem",
                                right: "1rem",
                            }}
                        >
                            <Fab
                                color="primary"
                                aria-label="add"
                                variant="extended"
                                onClick={handleClickOpen}
                                form="invoiceForm"
                            >
                                <DownloadForOfflineIcon sx={{ mr: 1 }} />
                                Generate Statement of Account
                            </Fab>

                        </Box>
                    }


                    {
                        // With InfiniteScrollSelect
                        <Dialog disableEscapeKeyDown open={open} onClose={handleClose} maxWidth="sm" fullWidth>
                            <DialogTitle sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '1.5rem' }}>Statement</DialogTitle>
                            <DialogContent>
                                <Box
                                    component="form"
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        padding: "24px",
                                        gap: 3,
                                        backgroundColor: '#f8f9fa',
                                        borderRadius: 2,
                                    }}
                                >
                                    <FormControl sx={{ width: '100%' }}>
                                        <InputLabel id="date-range-label" shrink>
                                            Date Range
                                        </InputLabel>
                                        <Select
                                            labelId="date-range-label"
                                            value={dateRange}
                                            displayEmpty
                                            onChange={handleDateRangeChange}
                                            variant="outlined"
                                            size="small"
                                            sx={{
                                                height: '40px',
                                                borderRadius: 1,
                                                '& .MuiOutlinedInput-root': {
                                                    height: '40px',
                                                },
                                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: '#1976d2',
                                                },
                                            }}
                                        >
                                            <MenuItem value="">
                                                <em>All</em>
                                            </MenuItem>
                                            <MenuItem value={30}>Last 30 Days</MenuItem>
                                            <MenuItem value={60}>Last 60 Days</MenuItem>
                                            <MenuItem value={90}>Last 90 Days</MenuItem>
                                            <MenuItem value="other">Other</MenuItem>
                                        </Select>

                                        {dateRange === "other" && (
                                            <Box sx={{ mt: 2, display: 'flex', gap: 2 }}>
                                                <TextField
                                                    label="Start Date"
                                                    type="date"
                                                    value={customStartDate || ""}
                                                    onChange={handleStartDateChange}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    variant="outlined"
                                                    size="small"
                                                    sx={{ width: '100%' }}
                                                />
                                                <TextField
                                                    label="End Date"
                                                    type="date"
                                                    value={customEndDate || ""}
                                                    onChange={handleEndDateChange}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    variant="outlined"
                                                    size="small"
                                                    sx={{ width: '100%' }}
                                                />
                                            </Box>
                                        )}
                                    </FormControl>

                                    <FormControl sx={{ width: '100%' }}>
                                        <InputLabel htmlFor="demo-dialog-report-type">Statement For</InputLabel>
                                        <Select
                                            value={reportType}
                                            onChange={handleReportTypeChange}
                                            input={<OutlinedInput label="Report Type" id="demo-dialog-report-type" />}
                                            sx={{
                                                borderRadius: 1,
                                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: '#1976d2',
                                                },
                                            }}
                                        >
                                            <MenuItem value="" disabled>None</MenuItem>
                                            <MenuItem value="job">Job</MenuItem>
                                            <MenuItem value="customer">Customer</MenuItem>
                                        </Select>
                                    </FormControl>

                                    {reportType && (
                                        <InfiniteScrollSelect
                                            collectionName={reportType === 'job' ? 'projects' : 'contacts'}
                                            label={reportType === 'job' ? 'customerReference' : 'fullName'}
                                            inputLabel={reportType === 'job' ? 'Select Project' : 'Select Contact'}
                                            onChange={handleOptionChange}
                                            value={selectedOption}
                                            reportType={reportType}
                                            invoiceData={data}
                                            sx={{
                                                width: '100%',
                                                mt: 1,
                                                borderRadius: 1,
                                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: '#1976d2',
                                                },
                                            }}
                                        />
                                    )}
                                </Box>
                            </DialogContent>
                            <DialogActions sx={{ justifyContent: 'center', padding: '16px' }}>
                                <Fab                                     aria-label="add"
                                    variant="extended" onClick={handleClose} sx={{ color: '#666' }}>Cancel</Fab>
                                <Fab
                                    onClick={() => handleCustomExport(searchCheck || dateRange ? filteredInvoices : invoices)}
                                    disabled={!selectedOption || isLoading}
                                    color="primary"
                                    aria-label="add"
                                    variant="extended"
                                >
                                    {isLoading ? (
                                        <CircularProgress size={24} sx={{ color: 'white' }} />
                                    ) : (
                                        'Generate Statement'
                                    )}
                                </Fab>
                            </DialogActions>
                        </Dialog>


                    }

                    {
                        // Without InfiniteScrollSelect
                        // <Dialog disableEscapeKeyDown open={open} onClose={handleClose}>
                        //     <DialogTitle>Statement</DialogTitle>
                        //     <DialogContent>
                        //         <Box component="form" sx={{ display: 'flex', justifyContent: "center", flexWrap: 'wrap', padding: "20px" }}>
                        //             <FormControl sx={{ m: 1, minWidth: 170 }}>
                        //                 <InputLabel htmlFor="demo-dialog-report-type">Statment For</InputLabel>
                        //                 <Select
                        //                     value={reportType}
                        //                     onChange={handleReportTypeChange}
                        //                     input={<OutlinedInput label="Report Type" id="demo-dialog-report-type" />}
                        //                 >
                        //                     <MenuItem value="" disabled>None</MenuItem>
                        //                     <MenuItem value="job">Job</MenuItem>
                        //                     <MenuItem value="customer">Customer</MenuItem>
                        //                 </Select>
                        //             </FormControl>

                        //             {reportType && (
                        //                 <FormControl sx={{ m: 1, minWidth: 170 }}>
                        //                     <InputLabel htmlFor="demo-dialog-option">{`Select ${reportType}`}</InputLabel>
                        //                     <Select
                        //                         value={selectedOption}
                        //                         onChange={handleOptionChange}
                        //                         input={<OutlinedInput label={`Select ${reportType}`} id="demo-dialog-option" />}
                        //                     >
                        //                         <MenuItem value="">
                        //                             <em>None</em>
                        //                         </MenuItem>
                        //                         {Array.from(
                        //                             new Set(
                        //                                 data.map((invoice) =>
                        //                                     reportType === 'job' ? invoice.invoiceJob : invoice.invoiceFor
                        //                                 )
                        //                             )
                        //                         ).map((uniqueValue) => (
                        //                             <MenuItem
                        //                                 key={uniqueValue}
                        //                                 value={uniqueValue}
                        //                             >
                        //                                 {reportType === 'job'
                        //                                     ? data.find((invoice) => invoice.invoiceJob === uniqueValue).jobName
                        //                                     : data.find((invoice) => invoice.invoiceFor === uniqueValue).customerName}
                        //                             </MenuItem>
                        //                         ))}
                        //                     </Select>
                        //                 </FormControl>
                        //             )}
                        //         </Box>
                        //     </DialogContent>
                        //     <DialogActions>
                        //         <Button onClick={handleClose}>Cancel</Button>
                        //         <Button onClick={() => handleCustomExport(searchCheck ? filteredInvoices : invoices)}
                        //             disabled={!selectedOption || isLoading}>
                        //             Generate Statement
                        //         </Button>
                        //     </DialogActions>
                        // </Dialog>
                    }

                    <Box
                        sx={{
                            position: "fixed",
                            bottom: "1rem",
                            "& > :not(style)": { m: 1 },
                        }}
                    >
                        {user.subscription !== "inactive" && (
                            <Link to="/invoices/new">
                                <Fab
                                    color="primary"
                                    aria-label="add"
                                    variant="extended"
                                    disabled={user.subscription == "inactive"}
                                >
                                    <AddIcon sx={{ mr: 1 }} />
                                    New
                                </Fab>
                            </Link>
                        )}
                    </Box>
                </div>
            </Fade>
            <DeleteDialog
                open={deleteOpen}
                confirmDelete={confirmDelete}
                setDialogOpen={setDeleteOpen}
                identifier={itemToDelete.identifier}
            />

            <Snackbar open={deletedOpen} autoHideDuration={2000} onClose={handleCloseDeleted}>
                <Alert onClose={handleCloseDeleted} severity="success" sx={{ width: "100%" }}>
                    Invoice successfully Deleted!
                </Alert>
            </Snackbar>

            <Snackbar
                open={notFoundInv}
                autoHideDuration={2000}
                onClose={handleNotFoundOpen}
            >
                <Alert
                    onClose={handleNotFoundOpen}
                    severity="error"
                    sx={{ width: "100%" }}
                >
                    Couldn't Find Invoices !
                </Alert>
            </Snackbar>
        </>
    );
};
