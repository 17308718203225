import "./App.css";
import React, { useEffect, useState } from "react";
import Layout from "./components/Layout";
import Checkbox from "@mui/material/Checkbox";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import tinycolor from "tinycolor2";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";

import CssBaseline from "@mui/material/CssBaseline";

import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import X from "./components/x";
import P from "./components/p";
import D from "./components/d";
import T from "./components/t";
import R from "./components/r";

import mainLogo from "./images/xpdtr.png";

import { auth, db, uiConfig } from "./config/config";
import { doc, setDoc, onSnapshot, getDoc } from 'firebase/firestore';

import { isSignInWithEmailLink, signInWithEmailLink, getAuth } from "firebase/auth";

import { SubscriptionProvider } from "./context/SubscriptionContext";
import { registerLicense } from "@syncfusion/ej2-base";
import Wrapper from "context/Wrapper";
import { sendEmailVerificationLink } from "components/2FAEmail";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { EmailVerificationHandler } from "pages/VerifyEmail";

const primary = "#002977";
const secondary = "#f89d07";
const secondarySecondary = "#f5c178";
const warning = "#931621";
const success = "#629677";
const info = "#931621";

const lightenRate = 7.5;
const darkenRate = 15;

const theme = createTheme({
  palette: {
    primary: {
      main: primary,
      light: tinycolor(primary).lighten(lightenRate).toHexString(),
      dark: tinycolor(primary).darken(darkenRate).toHexString(),
    },
    secondary: {
      main: secondary,
      secondary: secondarySecondary,
      light: tinycolor(secondary).lighten(lightenRate).toHexString(),
      dark: tinycolor(secondary).darken(darkenRate).toHexString(),
      contrastText: "#FFFFFF",
    },
    warning: {
      main: warning,
      light: tinycolor(warning).lighten(lightenRate).toHexString(),
      dark: tinycolor(warning).darken(darkenRate).toHexString(),
    },
    success: {
      main: success,
      light: tinycolor(success).lighten(lightenRate).toHexString(),
      dark: tinycolor(success).darken(darkenRate).toHexString(),
    },
    info: {
      main: info,
      light: tinycolor(info).lighten(lightenRate).toHexString(),
      dark: tinycolor(info).darken(darkenRate).toHexString(),
    },
    text: {
      primary: "#4A4A4A",
      secondary: "#6E6E6E",
      hint: "#B9B9B9",
    },
    background: {
      default: "#FAFAFA",
      light: "#F3F5FF",
    },
    action: {
      active: primary,

      hover: secondary,

      focus: secondary,

      selected: secondary,
    },
  },
  typography: {
    h4: {
      color: primary,
      fontFamily: "Roboto",
      fontWeight: 700,
    },
  },
});


const generateSessionId = () => {
  return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
};

const App = () => {
  const [isSignedIn, setIsSignedIn] = useState(false); // Local signed-in state.
  // const [hasAgreed, setHasAgreed] = useState(false);

  // Listen to the Firebase Auth state and set the local state.

  useEffect(() => {
    const unregisterAuthObserver = auth.onAuthStateChanged(async (user) => {
     
      if (user) {
        const userRef = doc(db, 'profiles', user.uid);
        const userSnapshot = await getDoc(userRef);

        const existingSessionId = localStorage.getItem('sessionId');
        const newSessionId = existingSessionId ? existingSessionId : generateSessionId();

        const emailSent = localStorage.getItem("emailSent");
        const isSignedIn = localStorage.getItem("isSignedIn");
        // Check if the user is signing in for the first time or after logging out

        const userData = userSnapshot.data();
        const enforceTwoFactor = userData?.enforceTwoFactor != undefined ? userData.enforceTwoFactor : true;
        if(enforceTwoFactor){
        if (!emailSent && isSignedIn == "false") {
          if (!userSnapshot.exists() || !userSnapshot.data().twoFactorEnabled || !existingSessionId) {
            await setDoc(userRef, { twoFactorEnabled: false }, { merge: true });
            // Send 2FA email if first signup or returning after logging out
            await sendEmailVerificationLink(user.email);
            // Sign out the user until they complete 2FA
            localStorage.setItem("emailSent", true);
            await auth.signOut();
            return;
          }
        }
      }

        // Check if Firestore sessionId is different
        const firestoreSessionId = userSnapshot.exists() ? userSnapshot.data().sessionId : null;
        if (firestoreSessionId !== newSessionId) {
          // Only update Firestore if the session ID is different
          await setDoc(userRef, { sessionId: newSessionId }, { merge: true });
        }
        // Listen for changes to the user's document
        const unsubscribe = onSnapshot(userRef, (doc) => {
          if (doc.exists()) {
            const data = doc.data();
            if (data.sessionId?.length > 0) {
              if (data.sessionId !== newSessionId) {
                // Another session has taken over
                localStorage.removeItem('sessionId');
                localStorage.removeItem('emailSent');
                auth.signOut();
              }
            }
          }
        });

        setIsSignedIn(true);
        localStorage.setItem('isSignedIn', 'true');
        localStorage.setItem('sessionId', newSessionId); // store sessionId in localStorage

        return () => unsubscribe();
      } else {
        // User is signed out
        setIsSignedIn(false);
        localStorage.setItem('isSignedIn', 'false');
      }
    });

    return () => unregisterAuthObserver();
  }, []);


  // const handleChange = (event) => {
  //   setHasAgreed(event.target.checked);
  // };

  registerLicense(
    "ORg4AjUWIQA/Gnt2VlhhQlJCfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5ad0NjUHpfdHZUQGZU"
  );


  // if (localStorage.getItem('emailForSignIn')) {
  //   return (
  //     <BrowserRouter>
  //       <Routes>
  //         <Route path="/verify" element={<EmailVerificationHandler />} />
  //       </Routes>
  //     </BrowserRouter>
  //   )
  // }

  return isSignedIn ? (
    <div className="App">
      <ThemeProvider theme={theme}>
        <SubscriptionProvider>
          <Wrapper>
            <Layout db={db} auth={auth} />
          </Wrapper>
        </SubscriptionProvider>
      </ThemeProvider>
    </div>
  ) : (
    <>
      <ThemeProvider theme={theme}>
        <Grid container component="main" sx={{ height: "100vh" }}>
          <CssBaseline />
          <Grid
            item
            xs={false}
            sm={4}
            md={7}
            sx={{
              backgroundColor: "#002977",
              backgroundSize: "cover",
              backgroundPosition: "center",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",

              width: "100%",
              height: "100vh",
            }}
          >
            <X style={{ height: "20%", width: "100%", display: "flex" }} />
            <P style={{ height: "20%", width: "100%", display: "flex" }} />
            <D style={{ height: "20%", width: "100%", display: "flex" }} />
            <T style={{ height: "20%", width: "100%", display: "flex" }} />
            <R style={{ height: "20%", width: "100%", display: "flex" }} />
          </Grid>
          <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
            <Box
              sx={{
                my: 8,
                mx: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img src={mainLogo} className={"heroClass"} alt="expediter" />
              <Typography
                component="h1"
                variant="h4"
                sx={{ textAlign: "center" }}
              >
                Welcome to XPDTR, NYC construction and real estate paperwork made
                easy!
              </Typography>
              {localStorage.getItem('emailForSignIn') ?
                <>
                  <Typography
                    component="h1"
                    variant="h4"
                    sx={{ textAlign: "center", marginTop: "30px", opacity: "0.6" }}
                  >
                    Verifying Your Email ..!
                  </Typography>
                  <BrowserRouter>
                    <Routes>
                      <Route path="/verify" element={<EmailVerificationHandler />} />
                    </Routes>
                  </BrowserRouter>
                </>
                :
                <>
                  <Typography component="p" variant="p" sx={{ marginTop: "20px" }}>
                    To get started, sign in or sign up using the links below
                  </Typography>
                  <Typography component="p" variant="p" sx={{ marginBottom: "20px" }}>
                    (registering is 100% free)
                  </Typography>
    
                  <div
                  >
                    <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />
                  </div>
                </>
              }
            </Box>
          </Grid>
        </Grid>
      </ThemeProvider>

    </>
  );
};

export default App;
