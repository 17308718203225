import * as React from "react";

const R = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={1333.333}
    width={1333.333}
    xmlSpace="preserve"
    viewBox="0 0 1333.33 1333.33"
    {...props}
  >
    <path
      style={{
        fill: "#fff",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="m0 0-62.857-62.857v-874.285L0-1000h250l61.429 62.858v249.999L624.286-1000h250l62.857 62.858v249.999l-62.857 61.429 62.857 62.857v375.714L750.001 0Zm311.429-375.714h250v-124.285h-250z"
      transform="matrix(1.33333 0 0 -1.33333 83.81 0)"
    />
  </svg>
);

export default R;
