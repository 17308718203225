import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { auth, db } from "config/config"; // Adjust the import path as per your config

const ProtectedRoutes = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [hasAgreed, setHasAgreed] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const checkUserAgreement = async () => {
      const user = auth.currentUser;

      if (user) {
        const userId = user.uid;
        try {
          const userDoc = await db.collection("profiles").doc(userId).get();
          if (userDoc.exists) {
            const userProfile = userDoc.data();
            if (userProfile.agreedToTerms) {
              setHasAgreed(true);
            }
          }
          setIsAuthenticated(true); // User is authenticated
        } catch (error) {
          console.error("Error checking user profile:", error);
        }
      }
      setIsLoading(false); // Done loading
    };

    checkUserAgreement();
  }, []);

  // If loading, you can show a spinner or nothing.
  if (isLoading) return <div></div>;

  // If user has agreed to the terms and is authenticated, allow them to access the page
  if (isAuthenticated && hasAgreed) {
    return <Outlet />; // This allows the user to navigate to the requested route
  }

  // If user has not agreed, redirect them to the agree-to-terms page
  return <Navigate to="/agreetoterms" />;
};

export default ProtectedRoutes;
