import React from "react";

// components

import { Grid, Fab, Typography, Fade } from "@mui/material";

export const DOB = () => {
  const source = "https://www.nyc.gov/site/buildings/index.page";
  //  const [source, setSource] = React.useState(false);

  return (
    <>
      <Fade in={true}>
        <iframe
          title="DOB"
          src={source}
          id="DOB"
          style={{ width: "100%", height: "calc(100vh - 110px)" }}
        />
      </Fade>
    </>
  );
};
