import { db } from 'config/config';
import { getAuth, isSignInWithEmailLink, signInWithEmailLink } from 'firebase/auth';
import { useEffect, useState } from 'react';
import { setDoc,doc } from 'firebase/firestore';

export const EmailVerificationHandler = () => {
    

    // const [isSignedIn,setisSignedIn] = useState()
 const generateSessionId = () => {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  };

    useEffect(() => {
        const auth = getAuth();

        console.log(isSignInWithEmailLink(auth, window.location.href))
        // Check if the current URL contains the email sign-in link
        if (isSignInWithEmailLink(auth, window.location.href)) {
            // Get the email from local storage (as it was stored before sending the verification link)
            let email = localStorage.getItem('emailForSignIn');

            // If the email is not stored in local storage, prompt the user to enter it manually
            if (!email) {
                return;
            }

            // Complete the sign-in process using the email and the link from the URL
            signInWithEmailLink(auth, email, window.location.href)
                .then(async (result) => {
                    const user = result.user;
                    const userRef = doc(db, 'profiles', user.uid);
                    await setDoc(userRef, { twoFactorEnabled: true }, { merge: true });

                    // Clear the email from local storage
                    localStorage.removeItem('emailForSignIn');
                    localStorage.setItem('isSignedIn', 'true');
                    
                    const existingSessionId = localStorage.getItem('sessionId');
                    // const newSessionId = existingSessionId ? existingSessionId : generateSessionId();
                    // localStorage.setItem('sessionId', newSessionId); // store sessionId in localStorage
                    // alert('Sign-in successful you can close this window!');
                    // You can redirect the user to the dashboard or home page after successful sign-in
                    window.location.href = '/';
                })
                .catch((error) => {
                    console.error('Error signing in with email link:', error);
                    alert('Error signing in. Please try again.');
                });
        }
    }, []);

    return null;
};
