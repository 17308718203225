import React, { useRef, useState,useEffect } from 'react';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import { ComponentToPrint } from 'pages/invoiceDetails';
import jsPDF from 'jspdf';


const DownloadInvoiceButton = ({ invoiceId }) => {
    const componentRef = useRef();
    const [isDownloadReady, setIsDownloadReady] = useState(false);
    const [isLoading, setIsLoading] = useState(true); // Loading state

    const handleDownload = async () => {
        const customWidth = 1000;
        const customHeight = 1000 * 1.414; // Aspect ratio of A4 paper (1:1.414)

        const doc = new jsPDF({
            unit: 'px',
            format: [customWidth, customHeight], // Set custom page dimensions
            orientation: 'portrait'
        });

        doc.setFont('Inter-Regular', 'normal');

        await doc.html(componentRef.current, {
            width: customWidth,
            windowWidth: customWidth, // Ensures full content width is captured
            margin: [10, 20, 30, 10],
            autoPaging: 'text',
            async callback(doc) {
                await doc.save('Invoice');
                setIsDownloadReady(false); // Clean up component after download
                setIsLoading(true); 
            },
        });
    };

    const handleDownloadClick = () => {
        setIsDownloadReady(true); // Load component to prepare for download
    };

    useEffect(()=>{
        if(isDownloadReady && !isLoading) handleDownload();
      },[isDownloadReady,isLoading])

    return (
        <>
            {isDownloadReady && (
                <div style={{ visibility: 'hidden', position: 'absolute', top: '-9999px' }}>
                    <ComponentToPrint ref={componentRef} tab={0} invoiceId={invoiceId} setIsLoading={setIsLoading} />
                </div>
            )}
            <DownloadForOfflineIcon onClick={handleDownloadClick} disabled={!isDownloadReady} />
        </>
    );
};

export default DownloadInvoiceButton;
 