import { useContext, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Grid, IconButton, Snackbar, TextField } from "@mui/material";
import { addDocumentType, editDocumentType, uploadFile } from "apis/documents";
import { Context } from "context/Wrapper";

const DocumentTypeDataDialog = ({
  open,
  reloadData,
  handleDocumentData,
  setDialogOpen,
  documentTypes,
  handleDownload,
}) => {
  const context = useContext(Context);

  const { groupId, isPro, userId } = context?.state?.userProfile || {};

  const [loading, setLoading] = useState(false);
  const [snackBar, setSnackBar] = useState(false);

  useEffect(() => {
    setLoading(false);
  }, []);
  useEffect(() => {
    setTimeout(() => {
      setSnackBar(false);
    }, 4000);
  }, [snackBar]);

  const handleClose = () => {
    setDialogOpen(false);
  };

  const currentType = documentTypes.find((type) => type.id === open);
  const checkDuplicateName = (name) => {
    return documentTypes.find((type) => type.name === name);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const data = new FormData(e.target);
    const dataObj = Object.fromEntries(data.entries());
    if (checkDuplicateName(dataObj.name)) {
      setSnackBar(
        <Snackbar
          open={true}
          autoHideDuration={6000}
          message="A Document Type with the same name already exists"
        />
      );
      return setLoading(false);
    }

    const file = dataObj?.file?.[0] || dataObj?.file;

    if (
      file.type !==
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document" && file.name!==""
    ) {
      setSnackBar(
        <Snackbar
          autoHideDuration={6000}
          open={true}
          message="The only supported format is .docx"
        />
      );

      setLoading(false);

      return;
    }

    delete dataObj.file;

    if (open === true) {
      if (groupId) dataObj.groupId = groupId;
      dataObj.userId = userId;
      const res = await addDocumentType(dataObj);
      const fileRes = await uploadFile(`documentTypesFiles/${res.id}`, file);
      await editDocumentType({
        data: { fileUrl: fileRes.metadata.fullPath },
        id: res.id,
      });
    } else if (open?.length > 0) {
      // await uploadFile(`documentTypesFiles/${open}`, file);
      await editDocumentType({
        data: { name: dataObj.name },
        id: open,
      });
    }
    setLoading(false);
    reloadData();
    e.target.reset();
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {snackBar}
      <form onSubmit={handleSubmit}>
        <DialogTitle id="alert-dialog-title">
          {open?.length > 0 ? "Edit" : "Add"} Document Type
        </DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            width: "400px",
            paddingBlock: "10px !important",
          }}
        >
          <TextField
            label={"Name"}
            type="text"
            name="name"
            onChange={(event) => {
              // handleChangeNewFormNote(event);
            }}
            fullWidth
            required
            defaultValue={currentType?.name}
          />
          <Grid
            container
            wrap="nowrap"
            alignItems="center"
            display={open?.length > 0 ? "none" : "flex"}
          >
            <Grid item>
              <TextField
                InputLabelProps={{ shrink: true }}
                label={"File"}
                inputProps={{
                  accept:
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                }}
                type="file"
                name="file"
                onChange={(event) => {
                  // handleChangeNewFormNote(event);
                }}
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
        <Button
            type="submit"
            // onClick={confirmDelete}
            variant="contained"
          
            disabled={loading}
          >
            {/* Save */}
            {open?.length > 0 ? "Save" : "Add"}
          </Button>
          <Button type="button"   variant="contained"
            color="secondary" onClick={handleClose}>
            {/* Cancel */}
            {open?.length > 0 ? "Close" : "Cancel"}
          </Button>
          
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DocumentTypeDataDialog;
