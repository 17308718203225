import * as React from "react";

const X = (props) => (
  <svg
    height={1333.333}
    width={1333.333}
    {...props}
    viewBox="0 0 1333.33 1333.33"
  >
    <path
      style={{
        fill: "#fff",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="m0 0-187.142-187.143L-374.286 0h-250l-62.857-62.857v-250l187.144-187.142-187.144-187.144v-249.999l62.857-62.858h250l187.144 187.143L0-1000h250l62.857 62.858v249.999L125.715-499.999l187.142 187.142v250L250 0Z"
      transform="matrix(1.33333 0 0 -1.33333 916.19 0)"
    />
  </svg>
);

export default X;
