import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { db } from "config/config";
import { addDoc, collection, doc } from "firebase/firestore";
import InfiniteScrollSelect from "./shared/InfiniteScrollSelect";
import { Box, Checkbox, Divider, FormControlLabel } from "@mui/material";

const FormDuplicateButton = ({
  formDetails,
  collection: collectionName,
  forms,
  openSucess,
}) => {
  // const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [targetDocument, setTargetDocument] = useState("");

  // const documents = useSelector((state) => state.documents); // Assuming you have a documents reducer

  const keyName = collectionName === "buildings" ? "buildingId" : "projectId";

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setTargetDocument("");
  };

  const handleDuplicate = () => {
    // dispatch(duplicateForm(formDetails, targetDocument));
    duplicateForm();
    handleClose();
  };

  const handleTargetChange = (event,_, doc) => {
    setTargetDocument(doc);
  };

  const duplicateForm = async () => {
    // write function to  copy application
    const thisForm = forms.find((form) => form.id === formDetails.row.formId);

    // if (isInactive) {
    //   return;
    // }

    const newForm = {
      ...thisForm,
      note: thisForm.note + " - Copy",
      dateCreated: Date.now(),
      dateUpdated: Date.now(),
    };
    delete newForm.id;

    if (collectionName === "buildings") {
      newForm.buildingId = targetDocument?.id || targetDocument;
      newForm.projectId = "";
    }

    if (collectionName === "projects") {
      newForm.projectId = targetDocument?.id || targetDocument;
      newForm.buildingId = "";
    }

    if (
      targetDocument?.groupId &&
      newForm.groupId !== targetDocument?.groupId
    ) {
      newForm.groupId = targetDocument?.groupId;
    }
    if (
      targetDocument?.userId &&
      newForm.userId !== targetDocument?.userId
    ) {
      newForm.userId = targetDocument?.userId;
    }

    await addDoc(collection(db, "filings"), newForm);
    openSucess("Form duplicated successfully");
    handleClose();
  };
  return (
    <>
      {/* <Button
        variant="outlined"
        startIcon={<ContentCopyIcon />}
        onClick={handleClickOpen}
      >
        Duplicate Form
      </Button> */}
      <GridActionsCellItem
        icon={<ContentCopyIcon />}
        onClick={() => handleClickOpen(formDetails)}
        label="Copy"
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Duplicate form ({formDetails.row.formName})
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" sx={{ mb: 2 }}>
            Choose where you want to duplicate this form, or select the same
            document:
          </DialogContentText>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <InfiniteScrollSelect
              collectionName={collectionName}
              label="customerReference"
              onChange={handleTargetChange}
              value={targetDocument?.id || targetDocument}
              name={collectionName}
              inputLabel="Target Document"
              sx={{ flexGrow: 1, mr: 2 }}
            />
            <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
            <FormControlLabel
              control={
                <Checkbox
                  checked={
                    (targetDocument?.id || targetDocument) ===
                    formDetails.row?.[keyName]
                  }
                  onChange={(e) => {
                    if (e.target.checked) {
                      setTargetDocument(formDetails.row?.[keyName]);
                    } else {
                      setTargetDocument("");
                    }
                  }}
                />
              }
              label="Duplicate in the same document"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={handleDuplicate}
            disabled={!targetDocument}
            autoFocus
          >
            Duplicate
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FormDuplicateButton;
