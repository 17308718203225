import { Button, Link, Fab, Box } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Slide from "@mui/material/Slide";
import TextField from "@mui/material/TextField";
import React from "react";
import { Save, Close } from "@mui/icons-material";

/* const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.main,
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
  fab: {
    margin: theme.spacing(2),
    position: "fixed",
    bottom: 0,
    right: 0,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    padding: "3rem",
  },
  paper: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    fontSize: "1.25rem",
  },
  textArea: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    fontSize: "1.25rem",
    width: "100%",
  },
  bigField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  menu: {
    width: 200,
  },
  formControl: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {},
})); */

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function EditToDo(props) {
  /*   const classes = useStyles(); */

  const [values, setValues] = React.useState({});
  const [isDirty, setIsDirty] = React.useState(false);

  React.useEffect(() => {
    let { title, description, details, assignedTo, due, id } = props.values;
    title = title === undefined ? "" : title;
    description = description === undefined ? "" : description;
    details = details === undefined ? "" : details;
    assignedTo = assignedTo === undefined ? "" : assignedTo;
    due = due === undefined ? "" : due;

    setValues({ title, description, details, assignedTo, due, id });
  }, [props.values]);

  const handleChange = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value });
    setIsDirty(true);
  };

  const handleClose = () => {
    props.toggleEdit();
  };

  return (
    <div>
      <Dialog
        maxWidth={"m"}
        open={props.open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <DialogTitle sx={{ backgroundColor: "#002977", color: "white" }}>
          {values.title}
        </DialogTitle>

        <form noValidate autoComplete="off" style={{ padding: "2rem" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <TextField
                id="title"
                label="Title"
                value={values.title}
                onChange={handleChange("title")}
                margin="normal"
                variant="outlined"
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <TextField
                id="description"
                label="Description"
                value={values.description}
                onChange={handleChange("description")}
                margin="normal"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                id="details"
                label="Details"
                value={values.details}
                onChange={handleChange("details")}
                margin="normal"
                variant="outlined"
                fullWidth
                multiline
                rows="10"
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                id="assignedTo"
                label="Assigned to"
                value={values.assignedTo}
                onChange={handleChange("assignedTo")}
                margin="normal"
                variant="outlined"
                fullWidth
                select
              >
                {props.contacts &&
                  props.contacts.length !== 0 &&
                  props.contacts.map((contact) => (
                    <MenuItem value={contact.fullName}>
                      {contact.fullName}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                id="due"
                label="Due Date"
                value={values.due}
                onChange={handleChange("due")}
                margin="normal"
                variant="outlined"
                fullWidth
                type="date"
              />
            </Grid>
          </Grid>
        </form>
        <Box
          sx={{
            position: "fixed",
            bottom: "1rem",
            right: "1rem",
            "& > :not(style)": { m: 1 },
          }}
        >
          {!props.isInactive && (
            <Fab
              color="primary"
              aria-label="add"
              variant="extended"
              onClick={() => props.handleEdit(values)}
              disabled={!isDirty || props.isInactive}
            >
              <Save sx={{ mr: 1 }} />
              Save
            </Fab>
          )}
          <Fab
            color="secondary"
            aria-label="add"
            variant="extended"
            onClick={props.closeEdit}
          >
            <Close sx={{ mr: 1 }} />
            Close
          </Fab>
        </Box>
      </Dialog>
    </div>
  );
}
