import React, { useState, useEffect, useContext } from "react";
import Task from "./Task";
import NewToDo from "./NewToDo";
import EditToDo from "./EditToDo";
import { v4 as uuidv4 } from "uuid";
import { Typography, Grid, Fade } from "@mui/material";
import { where, or } from "firebase/firestore";
import Board from "@lourenci/react-kanban";
import "@lourenci/react-kanban/dist/styles.css";
import "./board.css";
import { listenAndFilterTo, useFetchCollectionDocs } from "apis/dataSnapshots";
import { Context } from "context/Wrapper";
import { db } from "config/config";

const ToDo = (props) => {
  // Attach contact listener

  const context = useContext(Context);

  const { groupId, isPro, userId } = context?.state?.userProfile || {};
  const user = context?.state?.userProfile || {},
    { showAllDataFlag } = context?.state || {};

  const contacts = useFetchCollectionDocs({
    collectionName: "contacts",
    conditions:
      userId && groupId && isPro && showAllDataFlag
        ? [or(where("groupId", "==", groupId), where("userId", "==", userId))]
        : userId
          ? [where("userId", "==", userId)]
          : [],
    setContext: context.setState,
  });

  const [isUpdating, setUpdating] = useState(true);

  const [editOpen, setEditOpen] = useState({
    open: false,
    values: { title: "" },
  });

  // const { db, user, contacts, isPro, groupId, allData } = props;

  const [todos, setTodos] = useState();

  useEffect(() => {
    // const q =
    //   groupId && isPro && allData
    //     ? query(
    //         collection(db, "todos"),
    //         or(
    //           where("groupId", "==", groupId),
    //           where("userId", "==", user.uid),
    //         ),
    //       )
    //     : query(collection(db, "todos"), where("userId", "==", user.uid));

    // const unsubscribe = onSnapshot(q, (querySnapshot) => {
    //   const newTodos = querySnapshot.docs.map((doc) => {
    //     const data = doc.data();
    //     return {
    //       ...data,
    //       id: doc.id,
    //     };
    //   });

    //   setTodos(newTodos);
    //   setUpdating(false);
    // });

    const unsubscribe = listenAndFilterTo({
      setData: (docsData) => {
        setTodos(docsData);
        setUpdating(false);
      },
      user,
      setContext: context?.setState,
      condition: groupId && isPro && showAllDataFlag,
      collectionName: "todos",
    });

    return () => unsubscribe();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupId, isPro, showAllDataFlag, user]);

  const addNewCard = (newCard) => {
    setUpdating(true);
    var newBoard = JSON.stringify(todos[0].board);

    newBoard = JSON.parse(newBoard);

    let myuuid = uuidv4();

    newCard.id = myuuid;

    newBoard.columns[0].cards.push(newCard);

    db.collection("todos")
      .doc(todos[0].id)
      .update({
        board: newBoard,
        groupId: groupId,
        userId: user.uid,
        id: todos[0].id,
      })
      .then(() => {
        setUpdating(false);
      });
  };

  const handleDataChange = (newData) => {
    db.collection("todos").doc(todos[0].id).update({
      board: newData,
      userId: user.uid,
      groupId: groupId,
      id: todos[0].id,
    });
  };

  const openEdit = (values) => {
    setEditOpen({ open: true, values: values });
  };

  const closeEdit = () => {
    setEditOpen({ open: false, values: {} });
  };

  const handleEdit = (values) => {
    setUpdating(true);

    closeEdit();

    var newBoard = JSON.stringify(todos[0].board);

    newBoard = JSON.parse(newBoard);

    newBoard = {
      ...newBoard,
      columns: newBoard.columns.map((column) => {
        return {
          ...column,
          cards: column.cards.map((card) => {
            if (card.id !== values.id) {
              return card;
            }
            return values;
          }),
        };
      }),
    };

    db.collection("todos")
      .doc(todos[0].id)
      .update({
        board: newBoard,
        groupId: groupId,
        userId: user.uid,
        id: todos[0].id,
      })
      .then(() => {
        setUpdating(false);
      });
  };

  const handleDelete = (id) => {
    setUpdating(true);
    var newBoard = JSON.stringify(todos[0].board);

    newBoard = JSON.parse(newBoard);

    newBoard = {
      ...newBoard,
      columns: newBoard.columns.map((column) => {
        return {
          ...column,
          cards: column.cards.filter((card) => card.id !== id),
        };
      }),
    };

    db.collection("todos")
      .doc(todos[0].id)
      .update({
        board: newBoard,
        groupId: groupId,
        userId: user.uid,
        id: todos[0].id,
      })
      .then(() => {
        setUpdating(false);
      });
  };

  if (!todos || isUpdating) return <p />;

  const defaultBoard = {
    columns: [
      {
        id: 1,
        title: "Backlog",
        cards: [
          {
            id: 1,
            title: "This is a sample task",
            description: "Click on the arrow to see details...",
            details:
              "You can edit the task by clicking the pencil, or delete it by clicking on the trash icon. When you have started a task, just drag it to the 'In Progress' column - and when it's ready for review, drag it to the 'For Review' column, etc. It's that easy!",
          },
        ],
      },
      {
        id: 2,
        title: "In Progress",
        cards: [],
      },
      {
        id: 3,
        title: "For Review",
        cards: [],
      },
      {
        id: 4,
        title: "Completed",
        cards: [],
      },
      {
        id: 5,
        title: "Archived",
        cards: [],
      },
    ],
  };

  var board = todos.length > 0 ? todos[0].board : defaultBoard;

  if (todos.length === 0) {
    db.collection("todos").add({
      board: board,
      groupId: groupId,
      userId: user.uid,
    });
  }

  return (
    <>
      <Fade in={true}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={8} style={{ position: "sticky" }}>
            <Typography align="left" variant="h4">
              TASKS
            </Typography>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Board
              initialBoard={board}
              renderCard={(
                { title, description, due, details, assignedTo, id },
                { dragging },
              ) => (
                <Task
                  dragging={dragging}
                  title={title}
                  description={description}
                  due={due}
                  assignedTo={assignedTo}
                  details={details}
                  id={id}
                  openEdit={openEdit}
                  handleDelete={handleDelete}
                />
              )}
              onCardDragEnd={handleDataChange}
              onColumnDragEnd={handleDataChange}
            />
          </Grid>
        </Grid>
      </Fade>
      <NewToDo
        contacts={contacts}
        addNewCard={addNewCard}
        isInactive={props.isInactive}
      />
      <EditToDo
        contacts={contacts}
        open={editOpen.open}
        closeEdit={closeEdit}
        handleEdit={handleEdit}
        addNewCard={addNewCard}
        values={editOpen.values}
      />
    </>
  );
};

export default ToDo;
