import React, { useState, useEffect } from "react";

import { Grid, TextField, Fab, Box } from "@mui/material";

import columnDefs from "../config/columns.json";

import { Close } from "@mui/icons-material";

import axios from "axios";
// styles

import Fade from "@mui/material/Fade";
import CountCard from "../components/CountCard";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

export default function ProjectStatus(props) {
  const { thisProject, workTypeIDs } = props;

  if (workTypeIDs === undefined) {
    workTypeIDs = [];
  }

  workTypeIDs.indexOf(thisProject) === -1 && workTypeIDs.push(thisProject);

  const workTypeIDsString = workTypeIDs.join("','");

  const [table, setTable] = useState({
    showTable: false,
    columnDefs: "",
    data: [],
    title: "",
    showDetail: false,
    row: {},
  });
  const handleTableOpen = (title, tableName) => {
    setTable({
      showTable: true,
      columnDefs: columnDefs[tableName],
      data: DOBData[tableName].map((row, index) => ({ ...row, id: index })),
      title: title,
    });
  };

  const hideTable = () => {
    setTable({ showTable: false });
  };

  var [DOBData, setDOBData] = useState({
    DOBData: [],
    DOBNowData: [],
    violations: [],
    DOBNowPermits: [],
    complaints: [],
    asbestos: [],
    boilers: [],
    cats: [],
    ecb: [],
    DOBNowElectricalFilings: [],
    loading: true,
  });

  const handleSetRow = (event) => {
    setTable({ ...table, row: event.row, showDetail: true });
  };

  const handleCloseDetail = () => {
    setTable({ ...table, showDetail: false });
  };

  useEffect(() => {
    Promise.all([
      getDOBJobs(),
      getDOBNowJobs(),
      getPermits(),
      getElectricalNow(),
    ]).then(([DOBData, DOBNowData, DOBNowPermits, DOBNowElectricalFilings]) => {
      setDOBData({
        DOBData,
        DOBNowData,
        DOBNowPermits,
        DOBNowElectricalFilings,
        loading: false,
      });
    });
  }, []);

  const getPermits = () => {
    return axios
      .get(
        `https://data.cityofnewyork.us/resource/ipu4-2q9a.json?$where=job__ in('${workTypeIDsString}')&$limit=50000`,
      )
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        return [];
      });
  };

  const getElectricalNow = () => {
    return axios
      .get(
        `https://data.cityofnewyork.us/resource/dm9a-ab7w.json?$where=job_number in('${workTypeIDsString}')&$limit=50000`,
      )
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        return [];
      });
  };

  const getDOBJobs = () => {
    return axios
      .get(
        `https://data.cityofnewyork.us/resource/rvhx-8trz.json?$where=job__ in('${workTypeIDsString}')&$limit=50000`,
      )
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        return [];
      });
  };

  const getDOBNowJobs = () => {
    // Join the array of projects into a comma-separated strin

    return axios
      .get(
        `https://data.cityofnewyork.us/resource/w9ak-ipjd.json?$where=job_filing_number in('${workTypeIDsString}')&$limit=50000`,
      )
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        return [];
      });
  };

  if (DOBData.loading) {
    return (
      <>
        <Fade in>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              <CountCard
                number={DOBData.DOBData.length}
                title="BIS filings"
                loading={true}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <CountCard
                number={DOBData.DOBNowData.length}
                title="DOB Now! filings"
                loading={true}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <CountCard
                number={DOBData.DOBNowPermits.length}
                title="Permits"
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <CountCard
                number={DOBData.DOBNowElectricalFilings.length}
                title="DOB Now! Electrical filings"
                loading={true}
              />
            </Grid>
          </Grid>
        </Fade>
      </>
    );
  }
  return (
    <>
      {table.showTable ? (
        table.showDetail ? (
          <>
            <Grid container spacing={2} sx={{ w: "100%" }}>
              {table.columnDefs.map(
                (column) =>
                  table.row[column.field] &&
                  table.row[column.field] !== "0" && (
                    <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        label={column.headerName}
                        variant="outlined"
                        value={table.row[column.field]}
                        fullWidth
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                  ),
              )}
            </Grid>
            <Box
              sx={{
                position: "fixed",
                bottom: "1rem",
                right: "1rem",
                "& > :not(style)": { m: 1 },
              }}
            >
              <Fab
                color="secondary"
                aria-label="add"
                variant="extended"
                onClick={handleCloseDetail}
              >
                <Close sx={{ mr: 1 }} />
                Close
              </Fab>
            </Box>
          </>
        ) : (
          <div style={{ display: "flex", height: "100%" }}>
            <div style={{ flexGrow: 1 }}>
              <DataGrid
                slots={{
                  toolbar: GridToolbar,
                }}
                rows={table.data}
                columns={table.columnDefs}
                autosizeOnMount
                onRowClick={(e) => {
                  handleSetRow(e);
                }}
              ></DataGrid>{" "}
              <div
                style={{
                  position: "fixed",
                  bottom: 0,
                  right: 0,
                  margin: "1rem",
                }}
              >
                <Fab
                  color="secondary"
                  aria-label="add"
                  variant="extended"
                  onClick={() => hideTable()}
                >
                  <Close />
                  Back
                </Fab>
              </div>
            </div>
          </div>
        )
      ) : (
        <>
          <Fade in>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3}>
                <CountCard
                  number={DOBData.DOBData.length}
                  title="BIS filings"
                  handleTableOpen={handleTableOpen}
                  tableName={"DOBData"}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <CountCard
                  number={DOBData.DOBNowData.length}
                  handleTableOpen={handleTableOpen}
                  tableName={"DOBNowData"}
                  title="DOB Now! filings"
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <CountCard
                  number={DOBData.DOBNowPermits.length}
                  handleTableOpen={handleTableOpen}
                  tableName={"DOBNowPermits"}
                  title="Permits"
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <CountCard
                  number={DOBData.DOBNowElectricalFilings.length}
                  handleTableOpen={handleTableOpen}
                  tableName={"DOBNowElectricalFilings"}
                  title="DOB Now! Electrical filings"
                />
              </Grid>
            </Grid>
          </Fade>
        </>
      )}
    </>
  );
}
