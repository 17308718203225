import * as React from "react";
import { TextField, Box, Fab, IconButton, InputAdornment } from "@mui/material";
import { Save, Add, Delete } from "@mui/icons-material";

const Notes = (props) => {
  const mappedNotes = (notes) => {
    return notes.map((note, index) => (
      <TextField
        aria-label={note.timestamp}
        label={note.timestamp}
        multiline
        maxRows={4}
        value={note.note}
        fullWidth
        onChange={(e) => editNote(e.target.value, index)}
        sx={{ pb: "1rem" }}
        InputProps={{
          endAdornment: (
            <InputAdornment>
              <IconButton onClick={() => handleDelete(index)}>
                <Delete />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    ));
  };

  const [newNote, setNewNote] = React.useState("");

  const editNote = (text, newIndex) => {
    var oldNotes = decodedNotes;
    oldNotes = oldNotes.map((note, index) => {
      if (index === newIndex) {
        return { note: text, timestamp: new Date().toLocaleDateString() };
      } else {
        return note;
      }
    });
    setIsDirty(true);
    props.handleChangeNotes(JSON.stringify(oldNotes));
  };

  const handleSave = () => {
    setIsDirty(false);
    props.onSubmit();
  };

  const [isDirty, setIsDirty] = React.useState(false);

  var decodedNotes =
    props.notes && props.notes.length > 0 ? JSON.parse(props.notes) : [];

  const handleChange = (e) => {
    setNewNote(e.target.value);
    setIsDirty(true);
  };

  const handleDelete = (index) => {
    var oldNotes = decodedNotes;
    oldNotes.splice(index, 1);
    setIsDirty(true);
    props.handleChangeNotes(JSON.stringify(oldNotes));
  };

  const handleNewNote = (e) => {
    decodedNotes.push({
      note: newNote,
      timestamp: new Date().toLocaleDateString(),
    });
    props.handleChangeNotes(JSON.stringify(decodedNotes));
    setNewNote("");
  };

  return (
    <>
      {mappedNotes(decodedNotes)}
      <TextField
        multiline
        value={newNote}
        fullWidth
        onChange={handleChange}
        label="New note"
        onBlur={handleNewNote}
        InputProps={{
          endAdornment: (
            <InputAdornment>
              <IconButton onClick={handleNewNote}>
                <Add />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <Box
        sx={{
          position: "fixed",
          bottom: "1rem",
          right: "1rem",
          "& > :not(style)": { m: 1 },
        }}
      >
        {!props.isInactive && (
          <Fab
            color="primary"
            aria-label="add"
            variant="extended"
            disabled={!isDirty || props.isInactive}
            onClick={handleSave}
          >
            <Save sx={{ mr: 1 }} />
            Save
          </Fab>
        )}
      </Box>
    </>
  );
};

export default Notes;
