import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { Fragment } from "react";

export default function ConfirmDialog({
  open,
  title,
  content,
  handleClose,
  handleConfirm,
  btnTitle = "confirm",
}) {
  return (
    <Fragment>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open draggable dialog
      </Button> */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{content}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={handleConfirm}>{btnTitle}</Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
