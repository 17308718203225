import { collection, query, where, getDocs, addDoc } from "firebase/firestore";

// Reusable function for creating a new document with unique field
export const CreateUniqueDocument = async ({
    disableNavigation,
    openAddedSuccessfully = false,
    navigate,
    db,
    collectionName,
    data,
    uniqueFieldName = 'customerReference',
    loadingCallback,
    failureCallback,
    duplicateCallback,
    fromBuildingReferencesJobDetails
}) => {
    
    loadingCallback && loadingCallback();
    const uniqueFieldValue = data[uniqueFieldName].trim(); // Normalizing the unique field value
    
    try {
        // Query to check if any document with the same unique field already exists
        const q = query(collection(db, collectionName), where("groupId", "==", data.groupId));
        const querySnapshot = await getDocs(q);
        // Check if any document has the normalized value in the unique field
        let isDuplicate = false;
        querySnapshot.forEach((doc) => {
            const data = doc.data();
            if ((data[uniqueFieldName]?.toString() || '').toLowerCase() === uniqueFieldValue.toLowerCase()) {
                isDuplicate = true;
            }
        });

        if (!isDuplicate) {
            // If no duplicates, proceed to create the new document with the original input
            addDoc(collection(db, collectionName), {
                ...data,
                [uniqueFieldName]: uniqueFieldValue, // Store original version as the user inputted it
            }).then((docRef) => {
                if(openAddedSuccessfully){
                    if(!disableNavigation){
                        openAddedSuccessfully();
                       }else{
                       openAddedSuccessfully(docRef);
                    }
                };
                setTimeout(() => {
                    if(fromBuildingReferencesJobDetails?.fromBuildingReferencesJob && collectionName=='projects'){
                       if(!disableNavigation){
                        navigate(`/${collectionName}/details/${docRef.id}`, { state: {  fromBuildingReferencesJobDetails:fromBuildingReferencesJobDetails} });
                       }
                        // navigate(`/${collectionName}/details/${docRef.id}`, { state: {  fromBuildingReferencesJobDetails:fromBuildingReferencesJobDetails} });
                    }else{
                        if(!disableNavigation){
                            navigate(`/${collectionName}/details/${docRef.id}`);
                           }
                    // navigate(`/${collectionName}/details/${docRef.id}`);
                      }
                }, 1500); 
                // navigate(`/${collectionName}/details/${docRef.id}`);
            });

        } else {
            duplicateCallback && duplicateCallback(); // Call duplicate callback if provided
        }
    } catch (error) {
        console.error(`Error checking uniqueness for ${collectionName}: `, error);
        failureCallback && failureCallback(); // Call failure callback if provided
    }
};
