import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../config/config";
import {
  getStorage,
  ref,
  uploadBytes,
  uploadBytesResumable,
  deleteObject
} from "firebase/storage";

export const getDocumentTypes = async (setData) => {
  try {
    const groupId = localStorage.getItem("groupId");
    // let data

    // if(groupId)
    const data = await getDocs(
      query(collection(db, "documentTypes"), where("groupId", "==", groupId)),
    );
    //  else
    //   data = await getDocs(query(collection(db, "documentTypes")));

    if (data.empty) return setData([]);


    setData(
      data.docs
        ?.map((doc) => ({
          ...doc?.data(),
          id: doc.id,
          createTime: doc?._document?.createTime?.timestamp?.seconds,
        }))
        ?.sort((a, b) => b.createTime - a.createTime),
    );
  } catch (error) {
    console.log("error", error);
  }
};

// document type operations-------------
export const addDocumentType = async (data) => {
  // const createdAt= FieldValue.serverTimestamp()
  return await addDoc(collection(db, "documentTypes"), data);
};

export const editDocumentType = async ({ data, id }) => {
  const typeRef = doc(db, "documentTypes", id);
  return await updateDoc(typeRef, data);
};

// export const deleteDocumentType = async (id) => {
//   const typeRef = doc(db, "documentTypes", id);
//   return await deleteDoc(typeRef);
// };

export const deleteDocumentType = async (id) => {
  try {
    const typeRef = doc(db, "documentTypes", id);
    const docSnap = await getDoc(typeRef);

      const documentData = docSnap.data();
      
      const filePath = documentData?.fileUrl;

      if (filePath) {
        const storage = getStorage();
        const fileRef = ref(storage, filePath);
        await deleteObject(fileRef);
      }
      await deleteDoc(typeRef);
   
  } catch (error) {
    console.error("Error deleting document or file: ", error);
  }
};



export const uploadFile = async (path, file) => {
  const storage = getStorage();

  const filePathRef = ref(storage, path);

  return uploadBytes(filePathRef, file, {
    contentType: file.type,
    customMetadata: {
      name: file.name,
    },
  });
};

export const uploadFileResumable = (path, file) => {
  const storage = getStorage();

  const filePathRef = ref(storage, path);

  return uploadBytesResumable(filePathRef, file, {
    contentType: file.type,
    customMetadata: {
      name: file.name,
    },
  });
};

// get any doc in a collection hanlder
export const getDocData = async (id, collection) => {
  const docRef = doc(db, collection, id);
  const docSnap = await getDoc(docRef);

  if (docSnap.empty) return {};
  return docSnap.data();
};
