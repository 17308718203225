import React from "react";

var SubscriptionStateContext = React.createContext();
var SubscriptionDispatchContext = React.createContext();

function SubscriptionReducer(state, action) {
  switch (action.type) {
    case "SET_PRO":
      return { ...state, isPro: true };
    case "SET_STANDARD":
      return { ...state, isStandard: true };
    case "SET_INACTIVE":
      return { ...state, isInactive: true };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function SubscriptionProvider({ children }) {
  var [state, dispatch] = React.useReducer(SubscriptionReducer, {
    isPro: false,
    isStandard: false,
    isInactive: false,
  });
  return (
    <SubscriptionStateContext.Provider value={state}>
      <SubscriptionDispatchContext.Provider value={dispatch}>
        {children}
      </SubscriptionDispatchContext.Provider>
    </SubscriptionStateContext.Provider>
  );
}

function useSubscriptionState() {
  var context = React.useContext(SubscriptionStateContext);
  if (context === undefined) {
    throw new Error(
      "useSubscriptionState must be used within a SubscriptionProvider",
    );
  }
  return context;
}

function useSubscriptionDispatch() {
  var context = React.useContext(SubscriptionDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useSubscriptionDispatch must be used within a SubscriptionProvider",
    );
  }
  return context;
}

export {
  SubscriptionProvider,
  useSubscriptionState,
  useSubscriptionDispatch,
  setStandard,
  setPro,
  setInactive,
};

// ###########################################################

function setPro(dispatch) {
  console.log("setting pro");
  dispatch({
    type: "SET_PRO",
  });
}

function setStandard(dispatch) {
  console.log("setting standard");
  dispatch({
    type: "SET_STANDARD",
  });
}

function setInactive(dispatch) {
  console.log("setting inactive");
  dispatch({
    type: "SET_INACTIVE",
  });
}
