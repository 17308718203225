import * as React from "react";
import {
  Typography,
  styled,
  Paper,
  Grid,
  Fade,
  Button,
  Link,
} from "@mui/material";

import { useNavigate } from "react-router-dom";

import {
  FolderOpen as ProjectIcon,
  ContactPhone as PeopleIcon,
  Business as BuildingIcon,
} from "@mui/icons-material";

const Item = styled(Paper)(({ theme }) => {
  return {
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.primary.main,
    marginTop: theme.spacing(2),
    background: theme.palette.secondary.main,
  };
});

export const Dashboard = (props) => {
  const navigate = useNavigate();

  const getGreeting = () => {
    const myDate = new Date();
    const hrs = myDate.getHours();

    let greet;

    if (hrs < 12) greet = "Good morning";
    else if (hrs >= 12 && hrs <= 17) greet = "Good afternoon";
    else if (hrs >= 17 && hrs <= 24) greet = "Good evening";

    return greet;
  };

  const handleLink = (destination) => {
    navigate(destination);
  };

  //test commit

  return (
    <Fade in={true}>
      <div>
        <Typography
          style={{ marginBottom: "20px", textTransform: "uppercase" }}
          align="left"
          variant="h4"
          sx={{ display: { xs: "none", md: "flex" } }}
        >
          {getGreeting() + ", " + props.user.displayName}
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={4} sx={{ textAlign: "center" }}>
            <Button
              variant="contained"
              sx={{
                width: 400,
                height: 400,
                fontSize: 50,
                borderRadius: 20,
                flexDirection: "column",
              }}
              onClick={() => handleLink("/buildings")}
            >
              <BuildingIcon sx={{ height: 100, width: 100 }} />
              Buildings
            </Button>
          </Grid>
          <Grid item xs={12} md={6} lg={4} sx={{ textAlign: "center" }}>
            <Button
              variant="contained"
              sx={{
                width: 400,
                height: 400,
                fontSize: 50,
                borderRadius: 20,

                flexDirection: "column",
              }}
              onClick={() => handleLink("/projects")}
            >
              <ProjectIcon sx={{ height: 100, width: 100 }} />
              Jobs
            </Button>
          </Grid>
          <Grid item xs={12} md={6} lg={4} sx={{ textAlign: "center" }}>
            <Button
              variant="contained"
              sx={{
                width: 400,
                height: 400,
                fontSize: 50,
                borderRadius: 20,
                flexDirection: "column",
              }}
              onClick={() => handleLink("/contacts")}
            >
              <PeopleIcon sx={{ height: 100, width: 100 }} />
              Contacts
            </Button>
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <Item>
              <Typography
                style={{ marginBottom: "20px" }}
                align="left"
                variant="h4"
                sx={{ display: { xs: "none", md: "flex" } }}
              >
                Latest news from XPDTR:
              </Typography>{" "}
              <Typography
                style={{ marginBottom: "20px", fontSize: "1.85rem" }}
                align="left"
                variant="p"
                sx={{ display: { xs: "none", md: "flex" } }}
              >
                XPDTR is now in production! With instant access to the status of
                your buildings, permits and jobs from the DOB available on your
                desktop, tablet or smartphone - plus the ability to fill out and
                send over 100 different NYC agency forms and applications! We
                are adding auto-fill features to the forms one-by-one - you will
                start to see that the forms come up pre-filled with your data.
              </Typography>
            </Item>
          </Grid>
        </Grid>
      </div>
    </Fade>
  );
};
