import React, { useContext } from "react";

// components
import { DateTime } from 'luxon';
import { Fade, Grid, Typography, Box, Paper, Fab } from "@mui/material";

import { styled, useTheme } from "@mui/material/styles";
import { Context } from "context/Wrapper";
import { auth } from "config/config";

const ListItem = styled(Paper)(({ theme }) => {
  return {
    width: "100%",
    padding: theme.spacing(1),
    textAlign: "left",
    marginTop: theme.spacing(2),
    marginBottom: 0,
    color: theme.palette.primary.main,
    background: theme.palette.secondary.main,
  };
});

export const Subscription = ({ subscription }) => {
  /*   const handleCancel = () => {
    window.Paddle.Checkout.open({
      override: subscription?.cancelUrl,
    });
  }; */

  const context = useContext(Context);

  const currentUser = auth?.currentUser;

  const user = context?.state?.userProfile || {};






  // function formattedDate(date) {
  //   if (!date) return "";
  //   const d = new Date(date);
  //   const options = { day: "numeric", month: "long", year: "numeric" };
  //   const formattedDate = new Intl.DateTimeFormat("en-US", options).format(d);
  //   return formattedDate;
  // }

  // function formattedDate(date) {
  //   console.log(date)
  //   if (!date) return "";
  //   let d;

  //   const rfc2822Regex = /^\w{3}, \d{1,2} \w{3} \d{4} \d{2}:\d{2}:\d{2} GMT$/;
  //   if (rfc2822Regex.test(date)) {
  //     d = DateTime.fromRFC2822(date);
  //   } else {
  //     d = DateTime.fromISO(date);
  //   }
  
  //   const options = { day: "numeric", month: "long", year: "numeric" };
  //   const formattedDate = d.toLocaleString(options);
  //   console.log(formattedDate)
  //   return formattedDate; 
   
  // }
  

  function formattedDate(date) {
  
    if (!date) return "";
    let d;
  
    // Regular expressions to match the different date formats
    const rfc2822Regex = /^\w{3}, \d{1,2} \w{3} \d{4} \d{2}:\d{2}:\d{2} GMT[+-]\d{4} \(.+\)$/;
    const shortDateRegex = /^\d{4}-\d{2}-\d{2}$/; // ISO format (YYYY-MM-DD)
  
    // Check for RFC 2822 format
    if (rfc2822Regex.test(date)) {
      d = DateTime.fromRFC2822(date);
    } 
    // Check for ISO date format
    else if (shortDateRegex.test(date)) {
      d = DateTime.fromISO(date);
    } 
    // For other string formats,  parsing  using Luxon
    else {
      d = DateTime.fromJSDate(new Date(date)); // Fallback to JS Date parsing
    }
  
    // Validate the DateTime object
    if (!d.isValid) {
      console.error("Invalid date format:", date);
      return "Invalid date"; // Handle invalid date
    }
  
    const options = { day: "numeric", month: "long", year: "numeric" };
    const formattedDate = d.toLocaleString(options);
 
  
    return formattedDate; 
  }
  


  const subsriptionType = subscription?.subscriptionType;
  const subscriptionState = subscription?.subscription;

  const expirationDate = subscription?.expirationDate;



  let subscriptionDescription = "";

  if (
    /* subsriptionType === "pro_01hcysj1yj9ns71m7pe6x06ghx"  */
    subsriptionType === "standard"
  ) {
    subscriptionDescription = " Standard";
  } else if (
    /* subsriptionType === "pro_01hd3ssebf2pfkpy2ax8tbvc1k" ||
   subsriptionType === "pro_01hdrj761v6gaa2xe32kmeyqmv"  */
    subsriptionType === "pro"
  ) {
    subscriptionDescription = " Professional";
  }

  return (
    <>
      <Fade in={true}>
        <Grid container spacing={2}>
        
          <Grid item xs={12} md={6} lg={8} style={{ position: "sticky" }}>
            <Typography align="left" variant="h4">
              MY ACCOUNT
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <ListItem>
              <Typography variant="h6">
                Subscription status:
                <span style={{ textTransform: "uppercase" }}>
                  &nbsp;{`${subscriptionState}`}
                </span>
              </Typography>
            </ListItem>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <ListItem>
              <Typography variant="h6">
                Subscription type:
                <span style={{ textTransform: "uppercase" }}>
                  &nbsp;{`${subscriptionDescription}`}
                </span>
              </Typography>
            </ListItem>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <ListItem>
              <Typography variant="h6">
                {`User since: ${formattedDate(currentUser?.metadata?.creationTime)}`}
              </Typography>
            </ListItem>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <ListItem>
              <Typography variant="h6">
                Expires: {formattedDate(expirationDate)}
              </Typography>
            </ListItem>
          </Grid>
          {/*  <Grid item xs={12} md={6} lg={6}>
            <ListItem>
              <Typography variant="h6">
                {`Last payment: ${subscription?.lastPayment?.date} $${subscription?.lastPayment?.amount}`}
              </Typography>
            </ListItem>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <ListItem>
              <Typography variant="h6">
                {`Next payment: ${subscription?.nextPayment?.date} $${subscription?.nextPayment?.amount}`}
              </Typography>
            </ListItem>
          </Grid> */}
          {/*        <Box
            sx={{
              position: "fixed",
              bottom: "1rem",
              right: "1rem",
              "& > :not(style)": { m: 1 },
            }}
          >
            <Fab
              color="secondary"
              aria-label="add"
              variant="extended"
              href={subscription[0].management_urls.update_payment_method}
            >
              <PaymentIcon sx={{ mr: 1 }} />
              Update payment method
            </Fab>
            <Fab
              color="secondary"
              aria-label="add"
              variant="extended"
              href={subscription[0].management_urls.cancel}
            >
              <CreditCardOffIcon sx={{ mr: 1 }} />
              Cancel subscription
            </Fab>
          </Box> */}
        </Grid>
      </Fade>
    </>
  );
};
