import React from 'react';
import { Table, TableHead, TableRow, TableCell, TableBody, IconButton } from '@mui/material';
import { Delete } from "@mui/icons-material";
import dayjs from 'dayjs';

const CreateInvoiceTable = ({ itemList, handleDelete = false, tableType = "changes" }) => {

    const renderTableHead = () => {
        if (tableType === "changes") {
            return (
                <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Hours</TableCell>
                    <TableCell>Hourly Rate</TableCell>
                    <TableCell>Total Amount</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Action</TableCell>
                </TableRow>
            );
        } else if (tableType === "payments") {
            return (
                <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Amount</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Action</TableCell>
                </TableRow>
            );
        }
    };

    const renderTableBody = () => {
        return itemList.reverse().map((item, index) => (
            <TableRow key={index}>
                {tableType === "changes" && (
                    <>
                        <TableCell>{dayjs(item.itemDate).format('YYYY-MM-DD')}</TableCell>
                        <TableCell>{item.itemType}</TableCell>
                        <TableCell>{item.itemHours}</TableCell>
                        <TableCell>{item.itemHourPrice}</TableCell>
                        <TableCell>{item.itemAmount}</TableCell>
                        <TableCell>{item.itemDescription}</TableCell>
                        <TableCell>
                            <IconButton onClick={() => handleDelete(item.itemId, tableType)}>
                                <Delete />
                            </IconButton>
                        </TableCell>
                    </>
                )}
                {tableType === "payments" && (
                    <>
                        <TableCell>{dayjs(item.itemPaymentsDate).format('YYYY-MM-DD')}</TableCell>
                        <TableCell>{item.itemPaymentsAmount}</TableCell>
                        <TableCell>{item.itemPaymentsType}</TableCell>
                        <TableCell>
                            <IconButton onClick={() => handleDelete(item.itemPaymentsId, tableType)}>
                                <Delete />
                            </IconButton>
                        </TableCell>
                    </>
                )}
            </TableRow>
        ));
    };

    return (
        <Table sx={{ marginBottom: 6 }}>
            <TableHead>
                {renderTableHead()}
            </TableHead>
            <TableBody>
                {renderTableBody()}
            </TableBody>
        </Table>
    );
};

export default CreateInvoiceTable;
