import { createContext, useState } from "react";

const Wrapper = ({ children }) => {
  const [state, setState1] = useState({});
  const setState = (newState) =>
    setState1((state) => ({ ...state, ...newState }));

  return (
    <Context.Provider value={{ state, setState }}>
      {state?.snackbar || ""}
      {children}
    </Context.Provider>
  );
};

export const Context = createContext();
export default Wrapper;
