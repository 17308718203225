import React from "react";

import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";

import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import EditIcon from "@mui/icons-material/Edit";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";

import { DeleteDialog } from "../components/DeleteDialog";

import TextField from "@mui/material/TextField";

import IconButton from "@mui/material/IconButton";

const Task = ({
  title,
  description,
  assignedTo,
  details,
  due,
  id,
  openEdit,
  handleDelete,
}) => {
  const [expanded, setExpanded] = React.useState(false);
  const [confirmOpen, setConfirmOpen] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleConfirmOpen = () => {
    setConfirmOpen(true);
  };

  const handleConfirmDelete = () => {
    handleDelete(id);
    setConfirmOpen(false);
  };
  return (
    <>
      <Card>
        <CardHeader title={title} subheader={description} />
        <CardActions disableSpacing>
          <IconButton
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
            color="secondary"
          >
            <ExpandMoreIcon />
          </IconButton>
          <IconButton
            onClick={() =>
              openEdit({
                title,
                description,
                assignedTo,
                details,
                due,
                id,
              })
            }
            aria-label="edit"
            color="secondary"
          >
            <EditIcon />
          </IconButton>
          <IconButton
            onClick={handleConfirmOpen}
            aria-label="delete"
            color="secondary"
          >
            <DeleteIcon />
          </IconButton>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <TextField
              label="Details"
              multiline
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              fullWidth
              margin="normal"
              value={details}
            />
            <TextField
              label="Assigned to"
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              fullWidth
              margin="normal"
              value={assignedTo}
            />
            <TextField
              label="Due date"
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              fullWidth
              margin="normal"
              value={due}
            />
          </CardContent>
        </Collapse>
      </Card>
      <DeleteDialog
        identifier={title}
        title="Delete Task?"
        open={confirmOpen}
        setDialogOpen={setConfirmOpen}
        confirmDelete={handleConfirmDelete}
      ></DeleteDialog>
    </>
  );
};

export default Task;
