import React from "react";

// components

import { Fab, Fade, Box, Link } from "@mui/material";

import {
  Business as BuildingIcon,
  FireExtinguisher as FireExtinguisherIcon,
} from "@mui/icons-material";

export const Settings = () => {
  const [source, setSource] = React.useState(
    "https://www1.nyc.gov/site/buildings/index.page",
  );

  return (
    <>
      <Fade in={true}>
        <div>
          settings
          <Box
            sx={{
              position: "fixed",
              bottom: "1rem",
              right: "1rem",
              "& > :not(style)": { m: 1 },
            }}
          >
            <Fab
              color="secondary"
              aria-label="add"
              variant="extended"
              onClick={() =>
                setSource("https://www1.nyc.gov/site/buildings/index.page")
              }
            >
              <BuildingIcon sx={{ mr: 1 }} />
              DOB Home
            </Fab>
            <Fab
              color="secondary"
              aria-label="add"
              variant="extended"
              onClick={() => setSource("http://www.nyc.gov/dobnow")}
            >
              <BuildingIcon sx={{ mr: 1 }} />
              DOB Now!
            </Fab>
            <Fab
              color="secondary"
              aria-label="add"
              variant="extended"
              onClick={() => setSource("http://www.nyc.gov/bis")}
            >
              <BuildingIcon sx={{ mr: 1 }} />
              BIS
            </Fab>
            <Fab
              color="secondary"
              aria-label="add"
              variant="extended"
              onClick={() =>
                setSource("https://www1.nyc.gov/site/fdny/index.page")
              }
            >
              <FireExtinguisherIcon sx={{ mr: 1 }} />
              FDNY
            </Fab>
          </Box>
        </div>
      </Fade>
    </>
  );
};
