import React, { useState, useEffect, useContext,forwardRef } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";

import { Typography, styled,Snackbar } from "@mui/material";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

import Fab from "@mui/material/Fab";

import AddIcon from "@mui/icons-material/Add";
import Clear from "@mui/icons-material/Clear";
import Delete from "@mui/icons-material/Delete";
import { DeleteDialog } from "../components/DeleteDialog";

import { Link, Navigate } from "react-router-dom";

import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import MuiAlert from "@mui/material/Alert";
import SearchIcon from "@mui/icons-material/Search";
import Switch from "@mui/material/Switch";

import {
  collection,
  query,
  where,
  getDocs,
  setDoc,
  doc,
  or,
  onSnapshot,
} from "firebase/firestore";
import { ConstructionOutlined } from "@mui/icons-material";
import { Context } from "context/Wrapper";
import { listenAndFilterTo, useAdvancedDataListener } from "apis/dataSnapshots";
import { db } from "config/config";
import useCustomParams from "components/shared/useCustomParams";
import AdvancedTable from "components/AdvancedTable";
import tableConfig from "config/advancedTableColumnsControl.json";

const searchLimit = tableConfig?.searchMinLength;

const ListItem = styled(Paper)(({ theme, ...rest }) => {
  return {
    width: "100%",
    padding: theme.spacing(1),
    textAlign: "left",
    marginTop: theme.spacing(2),
    marginBottom: 0,
    color: theme.palette.primary.main,
    background: rest?.userGroupCheck
      ? theme.palette.secondary.secondary
      : theme.palette.secondary.main,
  };
});

let filterSearchedTimeout = null;


const Alert =forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const Buildings = (props) => {



  const buildingLocationState=useLocation()


  const [feedbackMessage, setFeedbackMessage] = useState({
    open: buildingLocationState.state?.feedback?.message ? true : false,
    message:  buildingLocationState.state?.feedback ?  buildingLocationState.state?.feedback?.message: '',
    severity:  buildingLocationState.state?.feedback ? buildingLocationState.state?.feedback?.severity: 'success',
  });


  const context = useContext(Context);
  const { params, updateParams } = useCustomParams();
 const { groupId, isPro } = context?.state?.userProfile || {};
    
  const user = context?.state?.userProfile || {},
    { showAllDataFlag } = context?.state || {};

  // const { db, user, isPro, groupId, allData } = props;

  // const { db, user, isPro, groupId, allData } = props;
  const [buildings, setBuildings] = useState([]);

  const [filteredBuildings, setFilteredBuildings] = useState([]);

  const [searchValue, setSearchValue] = useState("");

  const navigate = useNavigate();

  const [deleteOpen, setDeleteOpen] = useState(false);

  const [itemToDelete, setItemToDelete] = useState({
    itemId: "",
    identifier: "",
  });
  const { data, count,refetch } = useAdvancedDataListener({
    user,
    // conditions,
    // setLastRef: setLastVisible,
    condition: groupId && isPro && showAllDataFlag,
    
    collectionName: "buildings",
    // rowsLimit,
    // params,
  });

  useEffect(() => {
    updateParams({
      page: "",
      search: "",
      pageIncreasing: "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setBuildings(
      data?.map((doc) => ({
        ...doc,
        fullAddress: (doc.houseNumber || "") + " " + (doc.streetName || ""),
      }))
    );
  }, [data]);

  useEffect(() => {
    if (
      (searchValue?.length < searchLimit && searchValue?.length !== 0) ||
      !searchValue
    ) {
      if (searchValue?.length === 0) {
        updateParams({ search: "", pageSize: params.get("pageSize") });
      }
      return;
    }
    if (filterSearchedTimeout !== null) clearTimeout(filterSearchedTimeout);

    filterSearchedTimeout = setTimeout(() => {
      updateParams({ search: searchValue, pageSize: params.get("pageSize") });
      const newArr = buildings
        .filter(
          (item) =>
            item.customerReference
              ?.toLowerCase()
              .includes(searchValue.toLowerCase()) ||
            item.fullAddress
              ?.toLowerCase()
              .includes(searchValue.toLowerCase()) ||
            item.BIN?.toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase())
        )
        .map((item) => {
          let newCustomerReference = item.customerReference?.replace(
            new RegExp(searchValue, "gi"),
            (match) =>
              `<mark style="background: #2769AA; color: white;">${match}</mark>`
          );
          let newFullAddress = item.fullAddress?.replace(
            new RegExp(searchValue, "gi"),
            (match) =>
              `<mark style="background: #2769AA; color: white;">${match}</mark>`
          );
          let newBIN = item.BIN?.toString().replace(
            new RegExp(searchValue, "gi"),
            (match) =>
              `<mark style="background: #2769AA; color: white;">${match}</mark>`
          );
          return {
            ...item,
            customerReference: newCustomerReference,
            fullAddress: newFullAddress,
            BIN: newBIN,
          };
        });

      setFilteredBuildings(newArr);
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buildings, searchValue]);
  const handleInput = (e) => {
    let str = e.target.value;

    setSearchValue(str);
  };

  const createMarkup = (html) => {
    return { __html: html };
  };

  const handleTrackChange = async (event, id) => {
    const thisBuilding = doc(db, "buildings", id);
    setDoc(thisBuilding, { track: event.target.checked }, { merge: true }).then(
      () => {
        console.log("updated");
      }
    );
  };

  const handleDelete = (id, identifier) => {
    setItemToDelete({ itemId: id, identifier: identifier });
    setDeleteOpen(true);
  };


  // const confirmDelete = async () => {
  //   db.collection("buildings").doc(itemToDelete.itemId).delete();

  //   setBuildings((prevBuildings) => 
  //     prevBuildings.filter((building) => building.id !== itemToDelete.itemId)
  //   );
  //   openFeedbackMessage('Building deleted successfully!', 'success');

  //   setItemToDelete({ itemId: "", identifier: "" });
  //   setDeleteOpen(false);
  //   navigate("/buildings");
  // };


  const confirmDelete = async () => {

    const buildingId = itemToDelete.itemId;
  
    try {
      const batch = db.batch();
  
      const buildingRef = db.collection("buildings").doc(buildingId);
      batch.delete(buildingRef);

      const filingsRef = db.collection("filings").where("buildingId", "==", buildingId);
      const filingsSnapshot = await filingsRef.get();

      filingsSnapshot.forEach((doc) => {
        batch.delete(doc.ref);
      });
  
      await batch.commit();
  

      setBuildings((prevBuildings) =>
        prevBuildings.filter((building) => building.id !== buildingId)
      );

      refetch();

      openFeedbackMessage('Building deleted successfully!', 'success');
      setItemToDelete({ itemId: "", identifier: "" });
      setDeleteOpen(false);
      navigate("/buildings");
    } catch (error) {
      console.error("Error deleting building and associated filings: ", error);
      openFeedbackMessage('Failed to delete building. Please try again.', 'error');
    }
  };
  




  const searchCheck = searchValue.length >= searchLimit;
  const buildingCollection = searchCheck ? filteredBuildings : buildings;


  const openFeedbackMessage = (message, severity) => {
    setFeedbackMessage({ open: true, message, severity });
  };
  
  const handleCloseFeedbackMessage = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setFeedbackMessage({ ...feedbackMessage, open: false });
  };


  return (
    <>
      <Fade in={true}>
        <div>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={8} style={{ position: "sticky" }}>
              <Typography align="left" variant="h4">
                BUILDINGS
              </Typography>
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <Paper
                component="form"
                onSubmit={(e) => e.preventDefault()}
                sx={{
                  p: "2px 4px",
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  marginLeft: "auto",
                }}
              >
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search"
                  value={searchValue}
                  inputProps={{ "aria-label": "search" }}
                  onInput={handleInput}
                />

                {searchValue.length > 0 ? (
                <IconButton
                sx={{ p: "10px" }}
                aria-label="search"
                onClick={() => {
                  setSearchValue("");
                  updateParams({ search: "" });
                }}
              >
                    <Clear />
                  </IconButton>
                ) : (
                  <IconButton sx={{ p: "10px" }} aria-label="clear">
                    <SearchIcon />
                  </IconButton>
                )}
              </Paper>
            </Grid>
          </Grid>

          {/* <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
            textAlign={"left"}
            padding={"1rem 1rem 0 1rem"}
            sx={{ display: { xs: "none", md: "flex" } }}
          >
            <Grid item xs={12} md={4} lg={4}>
              <span>Name</span>
            </Grid>
            <Grid item xs={12} md={2} lg={2}>
              <span>BIN</span>
            </Grid>
            <Grid item xs={12} md={5} lg={5}>
              <span>Address</span>
            </Grid>
        
            <Grid item xs={12} md={1} lg={1}>
              <span>Delete</span>
            </Grid>
          </Grid>

          {buildingCollection.map((building) => (
            <ListItem
              userGroupCheck={
                building?.userId !== context?.state?.userProfile?.userId &&
                building?.groupId === context?.state?.userProfile?.groupId
              }
            >
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
              >
                <Grid item xs={12} md={4} lg={4}>
                  <Button
                    variant="contained"
                    style={{ width: "100%", justifyContent: "flex-start" }}
                    color={"primary"}
                    component={Link}
                    to={`/buildings/details/${building.id}`}
                  >
                    <span
                      dangerouslySetInnerHTML={createMarkup(
                        building.customerReference
                      )}
                    />
                  </Button>
                </Grid>
                <Grid item xs={4} md={2} lg={2}>
                  <span dangerouslySetInnerHTML={createMarkup(building.BIN)} />
                </Grid>
                <Grid item xs={6} md={5} lg={5}>
                  <span>
                    <span
                      dangerouslySetInnerHTML={createMarkup(
                        building.fullAddress
                      )}
                    />
                  </span>
                </Grid>
             
                <Grid item xs={1} md={1} lg={1}>
                  <IconButton>
                    <Delete
                      onClick={() =>
                        handleDelete(building.id, building.customerReference)
                      }
                    />
                  </IconButton>
                </Grid>
              </Grid>
            </ListItem>
          ))} */}
          <AdvancedTable
            data={buildingCollection}
            collectionName="buildings"
            columnsRaw={[
              // {
              // field: "id",
              // headerName: "ID",
              // },
              {
                field: "customerReference",
                headerName: "Name",
                renderCell: ({ row }) => (
                  <div
                    dangerouslySetInnerHTML={{ __html: row.customerReference }}
                  ></div>
                ),
              },
              {
                field: "BIN",
                headerName: "BIN",
                renderCell: ({ row }) => (
                  <div dangerouslySetInnerHTML={{ __html: row.BIN }}></div>
                ),
              },
              {
                field: "fullAddress",
                headerName: "Address",
                renderCell: ({ row }) => (
                  <div
                    dangerouslySetInnerHTML={{ __html: row.fullAddress }}
                  ></div>
                ),
              },
            ]}
            rowCount={searchCheck ? buildingCollection?.length :count}
            // rowCount={searchCheck ? filteredBuildings.length : buildings.length}
            handleDelete={handleDelete}
            isInactive={props.isInactive}
          />
          <Box
            sx={{
              position: "fixed",
              bottom: "1rem",
              "& > :not(style)": { m: 1 },
            }}
          >
            <Link to="/buildings/new">
              {!props.isInactive && (
                <Fab
                  color="primary"
                  aria-label="add"
                  variant="extended"
                  disabled={props.isInactive}
                >
                  <AddIcon sx={{ mr: 1 }} />
                  New
                </Fab>
              )}
            </Link>
          </Box>
        </div>
      </Fade>
      <DeleteDialog
        open={deleteOpen}
        confirmDelete={confirmDelete}
        setDialogOpen={setDeleteOpen}
        identifier={itemToDelete.identifier}
      />
        <Snackbar
  open={feedbackMessage.open}
  autoHideDuration={2000}
  onClose={handleCloseFeedbackMessage}
>
  <Alert
    onClose={handleCloseFeedbackMessage}
    severity={feedbackMessage.severity}
    sx={{ width: "100%" }}
  >
    {feedbackMessage.message}
  </Alert>
</Snackbar>
    </>
  );
};
